/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetBrandsDocument = gql`
    query GetBrands($companyId: Int!) {
  companyBrands(companyId: $companyId) {
    name
  }
}
    `;

/**
 * __useGetBrandsQuery__
 *
 * To run a query within a React component, call `useGetBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetBrandsQuery(baseOptions: Apollo.QueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables> & ({ variables: GetBrandsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
      }
export function useGetBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
        }
export function useGetBrandsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
        }
export type GetBrandsQueryHookResult = ReturnType<typeof useGetBrandsQuery>;
export type GetBrandsLazyQueryHookResult = ReturnType<typeof useGetBrandsLazyQuery>;
export type GetBrandsSuspenseQueryHookResult = ReturnType<typeof useGetBrandsSuspenseQuery>;
export type GetBrandsQueryResult = Apollo.QueryResult<GetBrandsQuery, GetBrandsQueryVariables>;
export const GetProductCategoriesDocument = gql`
    query GetProductCategories($itemsPerPage: Int, $delete: Boolean) {
  productCategories(itemsPerPage: $itemsPerPage, delete: $delete) {
    items {
      id
      name
      comment
      deleted
      parentCategory {
        id
        name
        deleted
      }
      company {
        id
        name
        address
        phone
        website
        deleted
      }
      childCategories {
        id
        name
        deleted
        parentCategory {
          id
          name
          deleted
        }
      }
    }
  }
}
    `;

/**
 * __useGetProductCategoriesQuery__
 *
 * To run a query within a React component, call `useGetProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductCategoriesQuery({
 *   variables: {
 *      itemsPerPage: // value for 'itemsPerPage'
 *      delete: // value for 'delete'
 *   },
 * });
 */
export function useGetProductCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>(GetProductCategoriesDocument, options);
      }
export function useGetProductCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>(GetProductCategoriesDocument, options);
        }
export function useGetProductCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>(GetProductCategoriesDocument, options);
        }
export type GetProductCategoriesQueryHookResult = ReturnType<typeof useGetProductCategoriesQuery>;
export type GetProductCategoriesLazyQueryHookResult = ReturnType<typeof useGetProductCategoriesLazyQuery>;
export type GetProductCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetProductCategoriesSuspenseQuery>;
export type GetProductCategoriesQueryResult = Apollo.QueryResult<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>;
export const GetProductsDocument = gql`
    query GetProducts($page: Int, $itemsPerPage: Int, $nameSkuBarcode: String, $categoryId: Int, $brand: String, $stockStatus: String, $id: Int) {
  products(
    page: $page
    itemsPerPage: $itemsPerPage
    nameSkuBarcode: $nameSkuBarcode
    categoryId: $categoryId
    brand: $brand
    stockStatus: $stockStatus
    id: $id
  ) {
    items {
      id
      name
      brand
      sku
      barcode
      unit {
        id
        name
      }
      alternativeUnit {
        id
        name
      }
      productStockStatus
      deleted
      productCategoryNew {
        id
        name
        deleted
        parentCategory {
          id
          name
          deleted
        }
      }
    }
    totalCount
    currentPage
    itemsPerPage
    pageCount
  }
}
    `;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      nameSkuBarcode: // value for 'nameSkuBarcode'
 *      categoryId: // value for 'categoryId'
 *      brand: // value for 'brand'
 *      stockStatus: // value for 'stockStatus'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
      }
export function useGetProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
        }
export function useGetProductsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
        }
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsSuspenseQueryHookResult = ReturnType<typeof useGetProductsSuspenseQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<GetProductsQuery, GetProductsQueryVariables>;
export const GetOneProductDocument = gql`
    query GetOneProduct($id: Int) {
  products(id: $id) {
    items {
      id
      name
      brand
      sku
      barcode
      image
      description
      criticalResidue
      unitGroup {
        id
        name
      }
      unit {
        id
        name
      }
      alternativeUnit {
        id
        name
      }
      alternativeValue
      alternativeUnitGroup {
        id
        name
      }
      productStockStatus
      deleted
      productAttributes {
        id
        name
        value
      }
      productCategoryNew {
        id
        name
        deleted
        parentCategory {
          id
          name
          deleted
        }
      }
      technologicalMapProductRelations {
        id
        technologicalMap {
          id
          deleted
          name
        }
      }
    }
    totalCount
    currentPage
    itemsPerPage
    pageCount
  }
}
    `;

/**
 * __useGetOneProductQuery__
 *
 * To run a query within a React component, call `useGetOneProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneProductQuery(baseOptions?: Apollo.QueryHookOptions<GetOneProductQuery, GetOneProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneProductQuery, GetOneProductQueryVariables>(GetOneProductDocument, options);
      }
export function useGetOneProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneProductQuery, GetOneProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneProductQuery, GetOneProductQueryVariables>(GetOneProductDocument, options);
        }
export function useGetOneProductSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOneProductQuery, GetOneProductQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOneProductQuery, GetOneProductQueryVariables>(GetOneProductDocument, options);
        }
export type GetOneProductQueryHookResult = ReturnType<typeof useGetOneProductQuery>;
export type GetOneProductLazyQueryHookResult = ReturnType<typeof useGetOneProductLazyQuery>;
export type GetOneProductSuspenseQueryHookResult = ReturnType<typeof useGetOneProductSuspenseQuery>;
export type GetOneProductQueryResult = Apollo.QueryResult<GetOneProductQuery, GetOneProductQueryVariables>;
export const GetUnitsDocument = gql`
    query GetUnits {
  relations {
    units {
      id
      name
      unitGroup {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetUnitsQuery__
 *
 * To run a query within a React component, call `useGetUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnitsQuery(baseOptions?: Apollo.QueryHookOptions<GetUnitsQuery, GetUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitsQuery, GetUnitsQueryVariables>(GetUnitsDocument, options);
      }
export function useGetUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitsQuery, GetUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitsQuery, GetUnitsQueryVariables>(GetUnitsDocument, options);
        }
export function useGetUnitsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUnitsQuery, GetUnitsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUnitsQuery, GetUnitsQueryVariables>(GetUnitsDocument, options);
        }
export type GetUnitsQueryHookResult = ReturnType<typeof useGetUnitsQuery>;
export type GetUnitsLazyQueryHookResult = ReturnType<typeof useGetUnitsLazyQuery>;
export type GetUnitsSuspenseQueryHookResult = ReturnType<typeof useGetUnitsSuspenseQuery>;
export type GetUnitsQueryResult = Apollo.QueryResult<GetUnitsQuery, GetUnitsQueryVariables>;
export const DeleteProductsDocument = gql`
    mutation DeleteProducts($productIds: [Int!]!) {
  deleteProducts(productIds: $productIds) {
    deleted
    failed
  }
}
    `;
export type DeleteProductsMutationFn = Apollo.MutationFunction<DeleteProductsMutation, DeleteProductsMutationVariables>;

/**
 * __useDeleteProductsMutation__
 *
 * To run a mutation, you first call `useDeleteProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductsMutation, { data, loading, error }] = useDeleteProductsMutation({
 *   variables: {
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useDeleteProductsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductsMutation, DeleteProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductsMutation, DeleteProductsMutationVariables>(DeleteProductsDocument, options);
      }
export type DeleteProductsMutationHookResult = ReturnType<typeof useDeleteProductsMutation>;
export type DeleteProductsMutationResult = Apollo.MutationResult<DeleteProductsMutation>;
export type DeleteProductsMutationOptions = Apollo.BaseMutationOptions<DeleteProductsMutation, DeleteProductsMutationVariables>;
export const CreateProductDocument = gql`
    mutation CreateProduct($companyId: Int!, $input: ProductInput!) {
  createProduct(companyId: $companyId, input: $input) {
    id
    name
    deleted
  }
}
    `;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($productId: Int!, $input: ProductInput!) {
  updateProduct(productId: $productId, input: $input) {
    id
    name
    brand
    sku
    barcode
    description
    criticalResidue
  }
}
    `;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const CreateProductCategoryDocument = gql`
    mutation CreateProductCategory($companyId: Int!, $input: ProductCategoryNewInput!) {
  createProductCategoryNew(companyId: $companyId, input: $input) {
    id
    name
    comment
    parentCategory {
      id
      name
      deleted
    }
    deleted
  }
}
    `;
export type CreateProductCategoryMutationFn = Apollo.MutationFunction<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>;

/**
 * __useCreateProductCategoryMutation__
 *
 * To run a mutation, you first call `useCreateProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductCategoryMutation, { data, loading, error }] = useCreateProductCategoryMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>(CreateProductCategoryDocument, options);
      }
export type CreateProductCategoryMutationHookResult = ReturnType<typeof useCreateProductCategoryMutation>;
export type CreateProductCategoryMutationResult = Apollo.MutationResult<CreateProductCategoryMutation>;
export type CreateProductCategoryMutationOptions = Apollo.BaseMutationOptions<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>;
export type GetBrandsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetBrandsQuery = { __typename?: 'Query', companyBrands: Array<{ __typename?: 'CompanyBrandsProxy', name: string } | null> };

export type GetProductCategoriesQueryVariables = Types.Exact<{
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  delete?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetProductCategoriesQuery = { __typename?: 'Query', productCategories?: { __typename?: 'ProductCategoryNewPaginated', items?: Array<{ __typename?: 'ProductCategoryNewProxy', id: number, name: string, comment?: string | null, deleted: boolean, parentCategory?: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean } | null, company?: { __typename?: 'CompanyProxy', id: number, name: string, address: string, phone: string, website?: string | null, deleted: boolean } | null, childCategories?: Array<{ __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean, parentCategory?: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null> | null }> | null } | null };

export type GetProductsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  nameSkuBarcode?: Types.InputMaybe<Types.Scalars['String']['input']>;
  categoryId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  brand?: Types.InputMaybe<Types.Scalars['String']['input']>;
  stockStatus?: Types.InputMaybe<Types.Scalars['String']['input']>;
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetProductsQuery = { __typename?: 'Query', products?: { __typename?: 'ProductPaginated', totalCount?: number | null, currentPage?: number | null, itemsPerPage?: number | null, pageCount?: string | null, items?: Array<{ __typename?: 'ProductProxy', id: number, name: string, brand?: string | null, sku?: string | null, barcode?: string | null, productStockStatus?: string | null, deleted: boolean, unit?: { __typename?: 'UnitProxy', id: number, name: string } | null, alternativeUnit?: { __typename?: 'UnitProxy', id: number, name: string } | null, productCategoryNew?: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean, parentCategory?: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null }> | null } | null };

export type GetOneProductQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetOneProductQuery = { __typename?: 'Query', products?: { __typename?: 'ProductPaginated', totalCount?: number | null, currentPage?: number | null, itemsPerPage?: number | null, pageCount?: string | null, items?: Array<{ __typename?: 'ProductProxy', id: number, name: string, brand?: string | null, sku?: string | null, barcode?: string | null, image?: string | null, description?: string | null, criticalResidue?: number | null, alternativeValue?: number | null, productStockStatus?: string | null, deleted: boolean, unitGroup?: { __typename?: 'UnitGroupProxy', id: number, name: string } | null, unit?: { __typename?: 'UnitProxy', id: number, name: string } | null, alternativeUnit?: { __typename?: 'UnitProxy', id: number, name: string } | null, alternativeUnitGroup?: { __typename?: 'UnitGroupProxy', id: number, name: string } | null, productAttributes?: Array<{ __typename?: 'ProductAttributesProxy', id: number, name?: string | null, value?: string | null }> | null, productCategoryNew?: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean, parentCategory?: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null, technologicalMapProductRelations?: Array<{ __typename?: 'TechnologicalMapProductRelationProxy', id: number, technologicalMap?: { __typename?: 'TechnologicalMapProxy', id: number, deleted: boolean, name: string } | null }> | null }> | null } | null };

export type GetUnitsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUnitsQuery = { __typename?: 'Query', relations: { __typename?: 'RelationProxy', units?: Array<{ __typename?: 'UnitProxy', id: number, name: string, unitGroup?: { __typename?: 'UnitGroupProxy', id: number, name: string } | null }> | null } };

export type DeleteProductsMutationVariables = Types.Exact<{
  productIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;


export type DeleteProductsMutation = { __typename?: 'Mutation', deleteProducts: { __typename?: 'DeleteResponse', deleted: Array<number>, failed: Array<number> } };

export type CreateProductMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  input: Types.ProductInput;
}>;


export type CreateProductMutation = { __typename?: 'Mutation', createProduct: { __typename?: 'ProductProxy', id: number, name: string, deleted: boolean } };

export type UpdateProductMutationVariables = Types.Exact<{
  productId: Types.Scalars['Int']['input'];
  input: Types.ProductInput;
}>;


export type UpdateProductMutation = { __typename?: 'Mutation', updateProduct: { __typename?: 'ProductProxy', id: number, name: string, brand?: string | null, sku?: string | null, barcode?: string | null, description?: string | null, criticalResidue?: number | null } };

export type CreateProductCategoryMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  input: Types.ProductCategoryNewInput;
}>;


export type CreateProductCategoryMutation = { __typename?: 'Mutation', createProductCategoryNew: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, comment?: string | null, deleted: boolean, parentCategory?: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean } | null } };
