import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {ReactSelect} from '@services/ui-components';
import {useTranslation} from 'react-i18next';
import {ApolloError, useReactiveVar} from '@apollo/client';
import ErrorService from '../../../services/ErrorService';
import {CategoryInterface} from '../../../services/ServicesCategoryNewInnit';
import i18next from 'i18next';
import {ErrorModalComponent} from '@me-pos/error-modal';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import {CompanyBrandsProxy} from '@me-team/host/graphql/types';
import i18n from '@me-team/host/src/i18n';
import {CategoryPickerCategory, CategoryPickerComponent} from '@me-pos/category-picker';
import transformProductCategories from '@me-team/host/src/utils/transformProductCategories';
import {Option, ProductsFilterI, StockStatus} from '../types';
import {useGetProductCategoriesQuery, useGetBrandsQuery} from "@me-team/host/src/modules/warehouses_goods/graphql/warehouses_goods.hooks";

interface ProductsFilterWrapperProps {
    setFilters: React.Dispatch<React.SetStateAction<ProductsFilterI>>;
}

const WarehousesGoodsFilter: React.FC<ProductsFilterWrapperProps> = ({setFilters}) => {
    const {t} = useTranslation();
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const dropdownRef = useRef(null);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [currentNameFilter, setCurrentNameFilter] = useState({
        categoryName: '',
        subCategoryName: '',
    });
    const [currentIdFilter, setCurrentIdFilter] = useState({categoryId: null, subCategoryId: null});
    const [search, setSearch] = useState('');
    const [selectedCategory, setSelectedCategory] = useState<CategoryInterface[]>(null);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedStockStatus, setSelectedStockStatus] = useState(null);

    const {data: productCategoriesData, loading} = useGetProductCategoriesQuery({
        context: {
            errorType: 'local'
        },
        onCompleted: (data) => {
            setSelectedCategory(
                transformProductCategories(data?.user?.company?.productCategories)
            );
        },
        onError: (error: ApolloError) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error))
        },
    });

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsPickerOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const {data: brandsData, loading: brandsDataLoading} = useGetBrandsQuery({
        variables: {
            companyId: +currentCompanyId,
        },
        context: {
            errorType: 'local'
        },
        onError: (error: ApolloError) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error))
        },
    });

    const brandsOptions = brandsData?.companyBrands?.map((brand: CompanyBrandsProxy) => {
        return {
            value: brand?.name,
            label: brand?.name
        }
    }) || [];

    const stockStatusOptions = Object.values(StockStatus).map((status) => {
        return {
            value: status,
            label: t(status),
        };
    });

    const handleBrandChange = (selectedOption: Option) => {
        setSelectedBrand(selectedOption);
    };
    const handleStockStatusChange = (selectedOption: Option) => {
        setSelectedStockStatus(selectedOption);
    };

    const handleCategoryNameChange = (categoryName: string) => {
        setCurrentNameFilter({categoryName, subCategoryName: ''});
    };
    const handleSubCategoryNameChange = (subCategoryName: string, categoryName: string = '') => {
        setCurrentNameFilter({categoryName, subCategoryName});
    };
    const handleCategoryIdChange = (categoryId: number) => {
        setCurrentIdFilter({categoryId, subCategoryId: null});
    };
    const handleSubCategoryIdChange = (categoryId: number, subCategoryId: number) => {
        setCurrentIdFilter((prevState) => ({
            ...prevState,
            categoryId: categoryId,
            subCategoryId: subCategoryId,
        }));
    };

    const handleApplyFilter = () => {
        setFilters({nameSkuBarcode: '', categoryId: null, brand: null, stockStatus: null});
        setFilters({
            nameSkuBarcode: search ? search : '',
            categoryId: currentIdFilter?.subCategoryId ? currentIdFilter?.subCategoryId : currentIdFilter?.categoryId,
            brand: selectedBrand?.value ? selectedBrand?.value : null,
            stockStatus: selectedStockStatus?.value ? selectedStockStatus?.value : null,
        });
        setIsPickerOpen(false);
    };

    const resetSelectedCategories = (categories: CategoryInterface[]) => {
        return categories?.map((category) => ({
            ...category,
            isPicked: false,
            isSubcategoryPiked: false,
            subcategoryList: category?.subcategoryList?.map((subcategory) => ({
                ...subcategory,
                isPicked: false,
            })),
        }));
    };

    const handleClearFilter = () => {
        setFilters({
            nameSkuBarcode: null,
            categoryId: null,
            brand: null,
            stockStatus: null,
        });
        setCurrentNameFilter({categoryName: '', subCategoryName: ''});
        setSearch('');
        setSelectedCategory(null);
        setSelectedBrand(null);
        setSelectedStockStatus(null);
        if (selectedCategory) {
            setSelectedCategory(resetSelectedCategories(selectedCategory));
        }

        setIsPickerOpen(false);
    };

    const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleApplyFilter();
        }
    };

    return (
        <>
            <Row className='gx-3'>
                <Col lg={10}>
                    <Row className='gx-3 h-100'>
                        <Col
                            lg={4}
                        >
                            <div className='position-relative mb-3 mb-lg-0'>
                                <input
                                    name='nameSkuBarcode'
                                    id="productsList-nameSkuBarcode-field"
                                    type='text'
                                    className='form-control px-6 pe-4'
                                    placeholder={t('Search by name, sku or barcode')}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    onKeyDown={handleSearchKeyDown}
                                />
                                <i
                                    className='bi bi-search position-absolute top-50 end-0 translate-middle-y text-dark me-2'></i>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className='position-relative mb-3 mb-lg-0'>
                                <input
                                    type='text'
                                    id='productsList-categoryId-field'
                                    className='form-control px-4 cursor-pointer'
                                    placeholder={t('Category')}
                                    value={
                                        currentNameFilter?.categoryName || currentNameFilter?.subCategoryName
                                            ? `${currentNameFilter?.categoryName}  ${currentNameFilter?.subCategoryName}`
                                            : ''
                                    }
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIsPickerOpen(!isPickerOpen);
                                    }}
                                    readOnly
                                />
                                <i
                                    className='bi bi-chevron-down position-absolute top-50 end-0 translate-middle-y text-dark me-2'></i>
                                <div
                                    ref={dropdownRef}
                                    className='dropdown position-absolute opacity-1 z-2 top-10  start-0 end-0 mt-2 bg-white shadow'>
                                    {productCategoriesData && selectedCategory && (
                                        <CategoryPickerComponent
                                            isOpen={isPickerOpen}
                                            locale={i18n.language}
                                            onSearchChange={(searchVealue: string) => {
                                            }}
                                            onChangeCategory={(currentCategory: CategoryPickerCategory) => {
                                                if (currentCategory.isPicked) {
                                                    handleCategoryNameChange(currentCategory?.name);
                                                    handleCategoryIdChange(currentCategory?.id);
                                                    setIsPickerOpen(false);
                                                } else {
                                                    setCurrentNameFilter({categoryName: '', subCategoryName: ''});
                                                }
                                            }}
                                            onChangeSubcategory={(currentSubcategory, currentCategory) => {
                                                if (currentSubcategory?.isPicked) {
                                                    handleSubCategoryNameChange(currentSubcategory?.name);
                                                    handleSubCategoryIdChange(currentCategory?.id, currentSubcategory?.id);
                                                    setIsPickerOpen(false);
                                                } else {
                                                    setCurrentNameFilter({categoryName: '', subCategoryName: ''});
                                                }
                                            }}
                                            serviceCategories={selectedCategory}
                                        />
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col
                            lg={2}
                        >
                            <ReactSelect
                                name='brand'
                                id="productsList-brand-field"
                                value={selectedBrand}
                                options={brandsOptions}
                                placeholder={t('Brand')}
                                onChange={handleBrandChange}
                                isSearchable={true}
                                onKeyDown={handleSearchKeyDown}
                            />
                        </Col>
                        <Col
                            lg={3}
                        >
                            <ReactSelect
                                name='stockStatus'
                                id='productsList-stockStatus-field'
                                value={selectedStockStatus}
                                options={stockStatusOptions}
                                placeholder={t('Product Availability')}
                                onChange={handleStockStatusChange}
                                isSearchable={false}
                                onKeyDown={handleSearchKeyDown}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col lg={2}>
                    <Row className='gx-3 h-100'>
                        <Col
                            lg={6}
                            className='mb-3 mb-lg-0'>
                            <Button
                                type='button'
                                variant='outline-dark'
                                className='border-grey w-100 h-100 px-1 fw-normal'
                                onClick={handleClearFilter}
                            >
                                {t('clear')}
                            </Button>
                        </Col>
                        <Col
                            lg={6}
                            className='mb-3 mb-lg-0'>
                            <Button
                                variant='primary'
                                className='w-100 h-100 px-1 fw-normal'
                                onClick={handleApplyFilter}
                            >
                                {t('apply')}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {isErrorModalOpen ?
                <ErrorModalComponent
                    i18n={i18next}
                    onClose={() => {
                        setIsErrorModalOpen(null)
                    }}
                    isOpen={!!isErrorModalOpen}
                    currentError={isErrorModalOpen}
                /> : null
            }
        </>

    );
};

export default WarehousesGoodsFilter;