import React from 'react';
import {Field, ErrorMessage, useField} from 'formik';
import {Button, Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import { TooltipCustom } from '@services/ui-components';

interface TextInputWithButtonProps {
  id: string;
  name: string;
  index: number;
  remove: (index: number) => void;
  label: string;
  infoText?: string;
}

const TextInputWithButton: React.FC<TextInputWithButtonProps> = ({
                                                                   id,
                                                                   name,
                                                                   index,
                                                                   remove,
                                                                   label,
                                                                   infoText
                                                                 }) => {
  const {t} = useTranslation();
  const [_, meta] = useField(name);

  return (
    <Row>
      <Col md={2}>
        <label
          htmlFor={id}
          className='text-dark fs-7 fw-bold'>
          {label}<>&nbsp;<span className='text-danger'>*</span></>
        </label>
      </Col>
      <Col md={10}>
        <div className='d-flex align-items-center w-100 gap-2'>
          <Field
            id={id}
            name={name}
            placeholder={t('Add property')}
            className={`form-control fs-7 ${meta.touched && meta.error ? 'is-invalid' : ''}`}
            maxLength={50}
          />
          <TooltipCustom placement='bottom' text={t('Delete attribute')}>
            <Button
              variant='outline-secondary'
              className='btn-square'
              style={{width: '49px', height: '49px'}}
              onClick={() => remove(index)}
            >
              <i className="bi bi-dash text-dark"></i>
            </Button>
          </TooltipCustom>
        </div>
        {
          infoText ?
            <div className='mt-1'>
                <span className='fs-7 text-grey'>
                  {t(infoText)}
                </span>
            </div>
            : null
        }
        <ErrorMessage name={name}>
          {msg => <div className='invalid-feedback d-block'>{msg}</div>}
        </ErrorMessage>
      </Col>
    </Row>
  );
};

export default TextInputWithButton;
