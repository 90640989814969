import { Modal, Button, Row, Col } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import { useTranslation } from "react-i18next";
import { ReactSelect } from "@services/ui-components";
import {
    useUpdateStorageMutation
} from "@me-team/host/src/modules/warehouses/graphql/warehouses.hooks";
import {warehouseValidationSchema, WearhousesModalProps} from "./modalsUtils"
import React from 'react';

const EditWarehouseModal: React.FC<WearhousesModalProps> = ({ show, handleModal, refetch, storages, warehouse }) => {
    const { t } = useTranslation();
    const isDisabled = warehouse?.default
    const initialValues = {
        name: warehouse?.name,
        storageType: warehouse?.type?.id,
        description: warehouse?.description || '',
        deleted: false
    };
    const [updateStorage] = useUpdateStorageMutation({
        context: {
            errorType: 'local',
        },
    });

    const handleSubmit = async (values: typeof initialValues, { setFieldError }: FormikHelpers<typeof initialValues>) => {
        if (isDisabled) {
            handleModal()
            return
        }
        try {
            const inputValues = {...values, description: values?.description.length > 0 ? values.description : null}
            const response = await updateStorage({
                variables: {
                    storageId: warehouse?.id,
                    input: inputValues
                }
            });

        } catch (error) {
            const graphQLError = error.graphQLErrors?.[0].extensions.validation[""][0].message
            if (graphQLError === "The storage already exists") {
                setFieldError('name', t('Warehouse with this name already exists!'));
                return
            }
        }
        refetch();
        handleModal()
    };

    return (
        <Modal size="lg" show={show} onHide={handleModal} centered>
            <Modal.Header className="border-0" closeButton>
                <h5 className="m-0"> {!warehouse?.deleted ? t('Edit warehouse') : t('Restore warehouse')}</h5>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={warehouseValidationSchema(t)}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                >
                    {({ values, errors, setFieldValue }) => (
                        <Form>
                            <Row className="m-0">
                                <Col lg={3} xs={4} className="d-flex align-items-center pr-0">
                                    <label htmlFor="createWarehouseModal-name-field" className="mb-0 text-dark form-label required text-break">
                                        <span className="text-dark fw-bold mt-1 fs-7">{t('Warehouse name')}</span>
                                        <span className="text-danger ms-1 text-break fs-7">*</span>
                                    </label>
                                </Col>
                                <Col lg={9} xs={8}>
                                    <Field
                                        type="text"
                                        name="name"
                                        placeholder={t('Add storage name')}
                                        className={`fs-7 form-control ${errors.name ? 'is-invalid' : ''}`}
                                        maxLength={50}
                                        id="createWarehouseModal-name-field"
                                        disabled={isDisabled}
                                    />
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col lg={3} xs={4} className="d-flex align-items-center pr-0">
                                </Col>
                                <Col lg={9} xs={8}>
                                    <span className={`fs-7 ${values?.name?.length > 50 ? "text-danger" : "text-grey"}`}>
                                        {values?.name?.length}/50
                                    </span>
                                    <ErrorMessage name="name" component="div" className="text-danger" />
                                </Col>
                            </Row>

                            {/* Warehouse Type */}
                            <Row className="pt-4 m-0">
                                <Col lg={3} xs={4} className="d-flex align-items-center pr-0">
                                    <label htmlFor="storageType" className="mb-0 text-dark form-label required text-break">
                                        <span className="text-dark fw-bold mt-1 fs-7">{t('Warehouse type')}</span>
                                        <span className="text-danger ms-1 text-break fs-7">*</span>
                                    </label>
                                </Col>
                                <Col lg={9} xs={8}>
                                    <ReactSelect
                                        options={storages}
                                        onChange={(option) => setFieldValue('storageType', option.value)}
                                        value={storages.find((option) => option.value === values.storageType) || null}
                                        disabled={isDisabled}
                                        placeholder={t('Select storage type')}
                                        isSearchable={true}
                                    />
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col lg={3} xs={4} className="d-flex align-items-center pr-0">
                                </Col>
                                <Col lg={9} xs={8}>
                                    <ErrorMessage name="storageType" component="div" className="text-danger" />
                                </Col>
                            </Row>

                            {/* Description */}
                            <Row className="pt-4 mb-3 mx-0">
                                <Col lg={3} xs={4}>
                                    <label
                                        htmlFor="createWarehouseModal-description-field"
                                        className="mb-0 text-dark text-break form-label"
                                        style={{ paddingTop: '15px' }}
                                    >
                                        <span className="text-dark fw-bold fs-7">{t('Description')}</span>
                                    </label>
                                </Col>
                                <Col lg={9} xs={8}>
                                    <Field
                                        as="textarea"
                                        name="description"
                                        placeholder={t('Add description')}
                                        className={`fs-7 form-control ${errors.description ? 'is-invalid' : ''}`}
                                        maxLength={400}
                                        id="createWarehouseModal-description-field"
                                        disabled={isDisabled}
                                    />
                                    <span className={`fs-7 ${values?.description?.length > 400 ? "text-danger" : "text-grey"}`}>
                                        {values?.description?.length || 0}/400
                                    </span>
                                    <ErrorMessage name="description" component="div" className="text-danger" />
                                </Col>
                            </Row>
                            <Row className="d-flex justify-content-end pt-6">
                                <Col xs={6} sm="auto" className="mb-sm-0 pe-2">
                                    <Button
                                        variant="outline-primary"
                                        className="fw-normal custom-btn rounded-1"
                                        onClick={handleModal}
                                    >
                                        {t("Cancel")}
                                    </Button>
                                </Col>
                                <Col xs={6} sm="auto" className="mb-sm-0 ps-2">
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="fw-bold custom-btn rounded-1"
                                    >
                                        {!warehouse?.deleted ? t('Save') : t('Restore')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default EditWarehouseModal;

