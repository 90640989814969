import React from "react";
import { Modal, Button, Col } from 'react-bootstrap';
import { Formik, Form,  FormikHelpers } from 'formik';
import { useTranslation } from "react-i18next";
import {useUpdateStorageMutation} from "@me-team/host/src/modules/warehouses/graphql/warehouses.hooks";
import { WearhousesModalProps } from "../../Warehouse/modals/modalsUtils";

const DeleteGoodsModals: React.FC<WearhousesModalProps> = ({ show, handleModal, refetch, warehouse  }) => {
    const { t } = useTranslation();
    const initialValues = {
        name: warehouse?.name,
        storageType: warehouse?.type?.id,
        description: warehouse?.description || '',
        deleted: true
    };
    const [updateStorage] = useUpdateStorageMutation({
        context: {
            errorType: 'local',
        },
    });
    const countProductsQuantity = data => data.reduce((count, item) => item.quantity > 0 ? count + 1 : count, 0)
    const handleSubmit = async (values: typeof initialValues, { setFieldError }: FormikHelpers<typeof initialValues>) => {
        try {
            const inputValues = {...values, description: values?.description.length > 0 ? values.description : null}
            const response = await updateStorage({
                variables: {
                    storageId: warehouse?.id,
                    input: inputValues
                }
            });
            refetch()
        } catch (error) {
            console.error('Error creating position:', error);
        }
        handleModal()
    };
    const asd = false
    return (
        <>
            {asd ? (
                <Modal
                    show={show}
                    onHide={() => handleModal()}
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header className="border-0" closeButton>
                        <h5 className='text-danger mb-0'>Видалення Товару Неможливе</h5>
                    </Modal.Header>
                    <Modal.Body className="text-center pb-4">
                        Видалення цього товару неможливо. Будь ласка, здійсніть списання або міграцію обраного товару зі складу,
                        перш ніж видалити.
                    </Modal.Body>
                    <Modal.Footer className="border-0 pt-0">
                        <Col>
                            <Button
                                className="btn btn-danger border-grey w-100 h-100 fw-normal"
                                onClick={() => handleModal()}
                            >
                                OK
                            </Button>
                        </Col>
                    </Modal.Footer>
                </Modal>
            ) : (
                <Modal
                    show={show}
                    onHide={() => handleModal()}
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header className="border-0" closeButton>
                        <h5 className=' mb-0'>Видалення Товарів Зі Складу</h5>
                    </Modal.Header>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        {() => (
                            <Form>
                                <Modal.Body className="text-center">
                                    <p className='mb-1'>
                                        {
                                            asd ? "Ви дійсно хочете видалити товар(и) зі складу?"
                                                :
                                                "Буде видалено 8 з 10 товарів. 2 товару(ів) видалити неможливо, оскільки вони є на складі. \n" +
                                                "Будь ласка, спишіть ці товари."
                                        }

                                    </p>
                                </Modal.Body>
                                <Modal.Footer className="border-0">
                                    <Col>
                                        <Button
                                            variant="outline-primary"
                                            className="btn btn-outline-primary border-grey w-100 h-100 fw-normal"
                                            onClick={() => handleModal(null)}
                                        >
                                            {t('Cancel')}
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            className="btn btn-primary w-100 text-truncate"
                                            type="submit"
                                        >
                                            {t('Delete')}
                                        </Button>
                                    </Col>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            )}
        </>
    );
};

export default DeleteGoodsModals