import React, {useEffect, useState} from 'react';
import { AccordionNav } from "@services/ui-components";
import { Accordion } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {AccordionEventKey} from "react-bootstrap/AccordionContext";

type accordionItemProps = {
    label: string,
    to: string,
    href?: string,
}

type accordionDataProps = {
    title: string,
    items: accordionItemProps[],
    icon: string
}

type SidebarMenuProps = {
    accordionsData: accordionDataProps[],
    handleCloseNavbar?: () => void,
    isRegistrationOrCreateOwner?: boolean
}

const SidebarMenu: React.FC<SidebarMenuProps> = (props: SidebarMenuProps) => {
    const {accordionsData, isRegistrationOrCreateOwner} = props;
    const location = useLocation();
    const [activeKey, setActiveKey] = useState<number | AccordionEventKey>(-1)

    const filteredItems = (accordion: accordionDataProps) => accordion.items.filter(Boolean);

    const currentActiveKey = accordionsData.findIndex((data) => {
        return filteredItems(data).some((item: accordionItemProps) => `/${item.to}` === location.pathname || location.pathname.startsWith(`/${item.to}`))
    });

    useEffect(() => {
        setActiveKey(currentActiveKey)
    }, [currentActiveKey]);

    return (
        <Accordion activeKey={`${activeKey}`} onSelect={(k) => setActiveKey(k)} className={`pb-5 ${isRegistrationOrCreateOwner ? "pe-none opacity-25" : ""}`}>
            {accordionsData.map((accordion, index) => (
                <AccordionNav key={index} title={accordion.title} items={filteredItems(accordion)}
                              icon={accordion.icon} eventKey={index.toString()} handleCloseNavbar={props.handleCloseNavbar}/>
            ))}
        </Accordion>
    );
}

export default SidebarMenu;
