import React from "react";
import {ProductCategoryNewProxy} from '@me-team/host/graphql/types';
import {useTranslation} from 'react-i18next';


interface RenderServiceCategoryProps {
    category: ProductCategoryNewProxy;
    isArchive: boolean;
}

const RenderProductCategoryName: React.FC<RenderServiceCategoryProps> = ({category, isArchive}) => {
    const {t} = useTranslation();

    if (!category) return null;

    const isSubCategory = !!category?.parentCategory?.name;
    const categoryName = category?.parentCategory?.name ? category?.parentCategory?.name : category?.name;
    const subCategoryName = category?.parentCategory?.name ? category?.name : '';
    const mainTextColor  = isArchive ? 'text-secondary' : 'text-dark';
    const subTextColor  = isArchive ? 'text-secondary' : 'text-grey';

    return  (
        <div>
      <span className={`${mainTextColor} d-block`}>
        {isSubCategory ? subCategoryName : categoryName}
      </span>
            {
                isSubCategory
                    ?
                    <span className={`fs-7 ${subTextColor}`}>
            {`(${categoryName})`}
          </span>
                    : null
            }
            {isArchive ? <span className='fs-7 text-grey'>({t('Archive')})</span> : null}
        </div>
    )
};

export default RenderProductCategoryName;