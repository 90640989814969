import {TFunction} from "i18next";
import * as Yup from "yup";

export const productFormValidationSchema = (t: TFunction, productExist?: boolean) => {
    return Yup.object().shape({
        quantity : Yup.number().required(t('validation.required')),
        unit: Yup.number().required(t('validation.required')),
        ...(productExist
            ? {} : {
                name: Yup.string().trim().required(t('validation.required')),
                brand: Yup.string().trim().required(t('validation.required')),
                sku: Yup.string().trim().required(t('validation.required')),
                barcode: Yup.string().trim().required(t('validation.required')),
                unitGroup: Yup.number().required(t('validation.required')),
            }
            ),
    });
};
