export enum StockStatus {
    InStock = 'In stock',
    OutOfStock = 'Out of stock',
    NotAdded = 'Not added',
}

export interface ProductsFilterI {
    nameSkuBarcode : string,
    categoryId: number,
    brand: string,
    stockStatus : string,
}

export interface Option {
    value: number;
    label: string;
}