import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col,Button, Row } from "react-bootstrap";
import {FiltersButton, TableHeader} from '@services/ui-components';
import VioletBadge from '@services/ui-components/src/VioletBadge/VioletBadge';
import {useReactiveVar} from "@apollo/client";
import {currentBranchIdVar, currentCompanyIdVar, currentUserRoleVar} from "@me-team/host/src/apollo/globalVar/state";
import AddNewProductListTable from "./AddNewProductListTable/AddNewProductListTable";
import {ProductsFilterI} from "../Products/types";
import ProductsFilterWrapper from "../Products/ProductsList/ProductsFilterWrapper";
import {UserRole} from "../../utils/enums";
import {ProductProxy} from "@me-team/host/graphql/types";
import {useLocation, useParams} from "react-router-dom";

const AddNewProductList: React.FC = () => {
    const { t } = useTranslation()
    const {id} = useParams();
    const location = useLocation();
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const [showModal, setShowModal] = useState(false);
    const [name, setName] = useState();
    const [showFilters, setShowFilters] = useState(false);
    const [existingProduct, setExistingProduct] = useState(null)
    const initialValueFilter: ProductsFilterI = {
        nameSkuBarcode: null,
        categoryId: null,
        brand: null,
    }
    const [filters, setFilters] = useState(initialValueFilter);
    const userRole = useReactiveVar(currentUserRoleVar);
    const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
    const handleAddWarehouseModal = (product?: ProductProxy) => {
        if (product) {
            setExistingProduct(product);
        }
        setShowModal(prevState => !prevState);
    }
    const toggleFilters = () => setShowFilters(!showFilters);
    return (
        <>
            <VioletBadge
                text={t('Lorem')}
            />
            <TableHeader
                title={t('Add products to the warehouse')}
                paths={[
                    { path: location?.pathname, label: t('Warehouse accounting') },
                    { path: "/warehouses", label: t('Warehouses') },
                    { path: `/warehouses/warehouses-goods/${currentBranchId}/${id}`, label: name },
                    { path: location?.pathname, label: t('Add products to the warehouse') },
                ]}

                colSize={9}
                btnColSize={3}
                buttons={
                    <Row
                        className='gx-1 w-100  d-flex flex-lg-nowrap justify-content-lg-end gap-3'>
                        {
                            !isEmployeeRole
                                ?
                                <Col
                                    xs={12}
                                    md={6}
                                    lg={7}
                                >
                                    <Button
                                        variant='primary'
                                        className='w-100 text-white mb-3 mb-lg-0 '
                                        onClick={() => handleAddWarehouseModal()}
                                    >
                                        <i className='bi bi-plus-lg fw-bold me-1 w-100 h-100'></i>
                                        {t('Add Product')}
                                    </Button>
                                </Col>
                                : null
                        }
                        <Col
                            xs={12}
                            md={6}
                            lg={5}
                            className='px-0'
                        >
                            <FiltersButton filter={filters} showFilters={showFilters} onClick={toggleFilters} classes="mb-3 mb-lg-0"/>
                        </Col>
                    </Row>
                }
                showFilters={showFilters}
            >
                <ProductsFilterWrapper setFilters={setFilters} stylesAddNewProductList/>
            </TableHeader>
            <AddNewProductListTable
                filters={filters}
                handleModal={handleAddWarehouseModal}
                setName={setName}
                showModal={showModal}
                onHide={()=> ''}
                existingProduct={existingProduct}
                setExistingProduct={()=> setExistingProduct(null) }

            />

        </>
    );
};

export default AddNewProductList;