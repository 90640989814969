import React from 'react';
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface CustomObject {
  [key: string]: any;
}
interface FiltersButtonProps {
  filter: CustomObject;
  showFilters: boolean;
  onClick: () => void;
  classes?: string
}

const FiltersButton: React.FC<FiltersButtonProps> = ({ filter, showFilters, onClick, classes }) => {
  const { t } = useTranslation();
  const hasActiveFilters = Object.values(filter).some(value => value !== null && value !== '' && value !== undefined);

  return (
    <Button
      variant="outline-primary"
      className={`${showFilters ? "bg-additional-violet" : ""} ${classes} fw-normal w-100 rounded-1`}
      onClick={onClick}
    >
      <i className="bi bi-funnel me-2 position-relative">
        {hasActiveFilters ? (
          <span className="d-block bg-orange position-absolute end-0 top-0 rounded-circle" style={{width: "5px", height: "5px"}}></span>
        ) : null}
      </i>
      {t("filters")}
    </Button>
  );
};

export default FiltersButton;
