import React from "react";
import { Modal, Button, Row, Col, Form as FormCh } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import { useTranslation } from "react-i18next";
import { ReactSelect } from "@services/ui-components";
import { WearhousesModalProps } from "../../Warehouse/modals/modalsUtils";
import {
    useMovementProductBetweenStoragesMutation,
} from "@me-team/host/src/modules/warehouses_goods/graphql/warehouses_goods.hooks";
import { UnitProxy } from "@me-team/host/graphql/types";
import * as Yup from 'yup';
interface Option {
    value: string | number | string[];
    label: string;
}
const MoveGoodsModal: React.FC<WearhousesModalProps> = ({
                                                            show,
                                                            handleModal,
                                                            refetch,
                                                            storages,
                                                            warehouse,
                                                            chosenProduct,
                                                            warehouses,
                                                            storageId
                                                        }) => {
    const { t } = useTranslation();
    const isDisabled = warehouse?.default;
    const initialValues = {
        storageId: '',
        quantity: '',
        unitId: '',
        moveAll: false
    };

    const validationSchema = () => Yup.object().shape({
        storageId: Yup.string()
            .required(t('Fill this field')),
        quantity: Yup.number().when('moveAll', {
            is: false,
            then: (schema) => {
                if (chosenProduct?.quantity === 0) {
                    return schema.notRequired();
                }
                return schema.required(t('Fill this field'));
            },
            otherwise: (schema) => schema.notRequired(),
        }),
        unitId: Yup.string().when('moveAll', {
            is: false,
            then: (schema) => {
                if (chosenProduct?.quantity === 0) {
                    return schema.notRequired();
                }
                return schema.required(t('Fill this field'));
            },
            otherwise: (schema) => schema.notRequired(),
        }),
    });

    const [movementProductBetweenStorages] = useMovementProductBetweenStoragesMutation({
        context: {
            errorType: 'local',
        },
    });

    const handleSubmit = async (
        values: typeof initialValues,
        { setFieldError }: FormikHelpers<typeof initialValues>
    ) => {
        try {

            const variables = {
                storageProductRelationId: +chosenProduct.id,
                storageId: +values.storageId,
                quantity: +values.quantity,
                unitId: +values.unitId
            };
            if (values.moveAll || chosenProduct?.quantity === 0) {
                variables.quantity = +chosenProduct?.quantity;
                variables.unitId = chosenProduct?.product?.unit?.id;
            }

            await movementProductBetweenStorages({
                variables
            });

            refetch();
            handleModal();
        } catch (error) {
            console.error(error);
        }
    };

    const transformUnitsForSelect = (units: UnitProxy[]) => {
        return units?.map(unit => ({
            value: unit?.id,
            label: unit?.name
        }));
    };

    const filteredWarehouses = warehouses.filter((wh: Option) => +wh.value !== +storageId)

    return (
        <Modal size="lg" show={show} onHide={handleModal} centered>
            <Modal.Header className="border-0" closeButton>
                <h5 className="m-0">Перемістити Товар</h5>
            </Modal.Header>
            <Modal.Body>
                {
                    chosenProduct?.quantity === 0 ?
                        <p className='mb-4 text-center'>
                            "Вибраний товар має нульовий залишок. Виберіть склад, до якого переносите записи товарів:"
                        </p>
                    : ""
                }

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    // validateOnChange={false}
                >
                    {({ values, errors, setFieldValue }) => (
                        <Form>
                            <Row>
                                <Col lg={2} xs={4} className="d-flex pr-0">
                                    <label htmlFor="storageId" className="mb-0 text-dark form-label required text-break">
                                        <span className="text-dark fw-bold mt-1 fs-7">Назва Складу</span>
                                        <span className="text-danger ms-1 text-break fs-7">*</span>
                                    </label>
                                </Col>

                                <Col lg={10} xs={8}>
                                    <ReactSelect
                                        options={filteredWarehouses}
                                        onChange={(option) => setFieldValue('storageId', option.value)}

                                        disabled={isDisabled}
                                        placeholder={"Оберіть Склад"}
                                        isSearchable
                                    />
                                    <ErrorMessage name="storageId" component="div" className="text-danger" />
                                    {chosenProduct?.quantity === 0 ? '' :
                                        <FormCh.Check
                                            className=' mt-3'
                                            type={"checkbox"}
                                            label={<span className="fs-7 text-dark">Перенести Все</span>}
                                            checked={values.moveAll}
                                            onChange={(e) => setFieldValue('moveAll', e.target.checked)}
                                        />
                                    }
                                </Col>
                            </Row>
                            {chosenProduct?.quantity === 0 ? '' :
                                <>
                                    <Row className="pt-3 ">
                                        <Col lg={2} xs={4} className="d-flex pr-0">
                                            <label htmlFor="quantity" className="mb-0 text-dark form-label required text-break">
                                                <span className="text-dark fw-bold mt-1 fs-7">Кількість для Додавання</span>
                                                <span className="text-danger ms-1 text-break fs-7">*</span>
                                            </label>
                                        </Col>
                                        <Col lg={10} xs={8}>
                                            <Field
                                                type="number"
                                                name="quantity"
                                                placeholder={"Додайте Кількість"}
                                                className={`fs-7 form-control ${values.moveAll && errors.quantity ? 'is-invalid' : ''}`}
                                                maxLength={50}
                                                id="quantity-field"
                                                disabled={values.moveAll}
                                            />
                                            <ErrorMessage name="quantity" component="div" className="text-danger" />
                                        </Col>
                                    </Row>
                                    <Row className="pt-3 mb-4">
                                        <Col lg={2} xs={4} className="d-flex pr-0">
                                            <label htmlFor="unitId" className="mb-0 text-dark form-label required text-break">
                                                <span className="text-dark fw-bold mt-1 fs-7">Одиниця Виміру</span>
                                                <span className="text-danger ms-1 text-break fs-7">*</span>
                                            </label>
                                        </Col>
                                        <Col lg={10} xs={8}>
                                            <ReactSelect
                                                options={transformUnitsForSelect(chosenProduct?.product?.unitGroup?.units)}
                                                onChange={(option) => setFieldValue('unitId', option.value)}
                                                disabled={values.moveAll}
                                                placeholder={"Оберіть Одиницю Виміру"}
                                                isSearchable
                                            />

                                            <ErrorMessage name='unitId'>{msg => <p
                                                className="fs-7 m-0 p-1 text-danger">{msg}</p>}
                                            </ErrorMessage>
                                        </Col>
                                    </Row>
                                </> }



                            <Row className="d-flex justify-content-end pt-6">
                                <Col xs={6} sm="auto" className="mb-sm-0 pe-2">
                                    <Button
                                        variant="outline-primary"
                                        className="fw-normal custom-btn rounded-1"
                                        onClick={handleModal}
                                    >
                                        {t("Cancel")}
                                    </Button>
                                </Col>
                                <Col xs={6} sm="auto" className="mb-sm-0 ps-2">
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="fw-bold custom-btn rounded-1"
                                    >
                                        {t('Save')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default MoveGoodsModal;
