import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {
    // BadgeAndButtonForArchive,
    Pagination,
    Spinner,
    // TooltipCustom
} from '@services/ui-components';

import {ApolloError, useReactiveVar} from '@apollo/client';
import ErrorService from '../../../services/ErrorService';
import {ProductProxy, StorageProductRelationProxy, StorageProxy, UnitProxy} from '@me-team/host/graphql/types';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {currentUserRoleVar} from '@me-team/host/src/apollo/globalVar/state';
// import {UserRole} from '../../../utils/enums'; TO FIX IT
import {Button, Container, Form, Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {getTextColorAvailabilityClass} from '../constants';
import RenderProductCategoryName from './RenderProductCategoryName';
import {
    useDeleteStorageProductRelationMutation,
    useGetProductsWarehouseQuery, useGetWarehousesGoodsQuery,

} from "@me-team/host/src/modules/warehouses_goods/graphql/warehouses_goods.hooks";
import AddGoodsWarehouseModal from "../modals/AddGoodsWarehouseModal";
import WriteOffModal from "../modals/WriteOffModal";
import MoveGoodsModal from "../modals/MoveGoodsModal";
import DeleteGoodsModals from "../modals/DeleteGoodsModals";
import {useGetWarehousesQuery} from "@me-team/host/src/modules/warehouses/graphql/warehouses.hooks";

interface ProductsTableProps {
    filters: {
        brand: string,
        categoryId: number,
        nameSkuBarcode: string,
        stockStatus: string
    }
}

const WarehousesGoodsTable: React.FC<ProductsTableProps> = ({filters}) => {
    const {t} = useTranslation();
    const { branchId,  storageId} = useParams();
    const navigate = useNavigate();
    const userRole = useReactiveVar(currentUserRoleVar);
    // const isEmployeeRole = +userRole === UserRole.EMPLOYEE;  TO FIX IT
    const parentCheckboxRef = useRef<HTMLInputElement>(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
    const [parentChecked, setParentChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [markedForDeletionArray, setMarkedForDeletionArray] = useState<number[]>([]);
    const [showAddModal, setShowAddModal] = useState(false)
    // console.log(markedForDeletionArray)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showMoveModal, setShowMoveModal] = useState(false)
    const [showWriteOfModal, setShowWriteOfModal] = useState(false)
    const [chosenProduct, setChosenProduct] = useState<StorageProductRelationProxy>(null)

    const resetCurrentPage = () => {
        setCurrentPage(1);
    };

    useEffect(() => {
        resetCurrentPage();
    }, [filters?.nameSkuBarcode, filters?.categoryId, filters?.brand, filters?.stockStatus]);
    const [deleteStorageProductRelation] = useDeleteStorageProductRelationMutation({
        context: {
            errorType: 'local',
        },
    });
    const {data: productsData, loading: productsDataLoading} = useGetProductsWarehouseQuery({
        variables: {
            page: currentPage,
            itemsPerPage: itemsPerPage,
            nameSkuBarcode: filters?.nameSkuBarcode,
            categoryId: filters?.categoryId,
            brand: filters?.brand,
            stockStatus: filters?.stockStatus

        },
        context: {
            errorType: 'global'
        },
        onError: (error: ApolloError) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error))
        },
    });
    const {data: warehousesGoods, loading, refetch} = useGetWarehousesGoodsQuery({
        variables: {
            page: currentPage,
            itemsPerPage: itemsPerPage,
            nameSkuBarcode: filters?.nameSkuBarcode,
            categoryId: filters?.categoryId,
            brand: filters?.brand,
            stockStatus: filters?.stockStatus,
            branchesPaginatedId: +branchId,
            storagesPaginatedId: +storageId
        },
        context: {
            errorType: 'global'
        },
        onError: (error: ApolloError) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error))
        },
    });

    const { data, loading: loadingWarehouses, error,  } = useGetWarehousesQuery({
        variables: { page: 1, itemsPerPage: 10000, currentBranchId: +branchId },
        context: {
            errorType: 'global',
        },
    })
    const storageProducts = warehousesGoods?.user?.company?.branchesPaginated?.branches[0]?.storagesPaginated?.storages[0]?.storageProducts?.storageProductRelations;
    const warehouses = data?.user?.company?.branchesPaginated?.branches[0]?.storagesPaginated?.storages
    const products = productsData?.products?.items // Убрать
    const pageCount = warehousesGoods?.user?.company?.branchesPaginated?.branches[0]?.storagesPaginated?.pageCount
    const totalItems = warehousesGoods?.user?.company?.branchesPaginated?.branches[0]?.storagesPaginated?.totalCount ;
    const writeOffReasons = warehousesGoods?.relations?.writeOffReasons?.map(i => ({
        value: i?.id,
        label: i?.name
    }))

    const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
    const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

    useEffect(() => {
        if (markedForDeletionArray.length === 0) {
            setParentChecked(false);
            setIndeterminate(false);
        } else if (markedForDeletionArray.length === products?.length) {
            setParentChecked(true);
            setIndeterminate(false);
        } else {
            setParentChecked(false);
            setIndeterminate(true);
        }

        if (parentCheckboxRef.current) {
            parentCheckboxRef.current.indeterminate = indeterminate;
        }
    }, [markedForDeletionArray, products?.length, indeterminate]);

    const handleChildCheckboxChange = (id: number) => {
        setMarkedForDeletionArray((prevMarkedForDeletionArray) =>
            prevMarkedForDeletionArray.includes(id)
                ? prevMarkedForDeletionArray.filter((markedId) => markedId !== id)
                : [...prevMarkedForDeletionArray, id]
        );
    };


    const handleParentCheckboxChange = () => {
        if (indeterminate) {
            setMarkedForDeletionArray([]);
            setParentChecked(false);
        } else if (parentChecked) {
            setMarkedForDeletionArray([]);
        } else {
            setMarkedForDeletionArray(products?.map((product) => product.id) || []);
        }
    };

    const handleRecoveryProduct = (id: number) => {
        // TODO добавить ссылку, на страницу восстановления архива, когда она будет готова
        navigate(`/`);
    }

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };
    const handleAddModal = (product?: StorageProductRelationProxy) => {
        setChosenProduct(product)
        setShowAddModal(prevState => !prevState)
    }
    const handleMoveModal = (product?: StorageProductRelationProxy) => {
        setChosenProduct(product)
        setShowMoveModal(prevState => !prevState)
    }
    const handleWriteOfModal = (product?: StorageProductRelationProxy) => {
        setChosenProduct(product)
        setShowWriteOfModal(prevState => !prevState)
    }
    const handleDelete = async (event) => {
        event.stopPropagation();
        for (const id of markedForDeletionArray) {
            try {
                await deleteStorageProductRelation({
                    variables: { id },
                });
            } catch (error) {
                console.error(`Failed to delete item with id ${id}:`, error);
            }
        }
        setMarkedForDeletionArray([]);
        // Optionally, refetch data after all deletions are done
        // refetchGetWarehousesGoods(); // Call your refetch function here
    };

    const transformUnitsForSelect = () => {
        return warehouses?.map(i  => ({
            value: i?.id,
            label: i?.name
        }));
    };

    if (productsDataLoading || loading || loadingWarehouses) return <Spinner/>;

    return (
        <>

            <div className='col-12 table-responsive scrollbar position-relative'>
                {
                    !!products?.length ? (
                        <table className='table table-borderless'>
                            <thead>
                            <tr className='fs-7'>
                                <th className='py-3 col-1 ps-4 pe-2 align-middle'>
                                    <div className='d-flex align-items-center gap-3'>
                                        <Form.Check
                                            type="checkbox"
                                            ref={parentCheckboxRef}
                                            checked={parentChecked}
                                            onChange={handleParentCheckboxChange}
                                            className='m-0'
                                            style={{ width: '16px', height: '16px' }}
                                        />

                                        {markedForDeletionArray.length > 0 ?
                                            <Button
                                                variant='outline-danger'
                                                className='border-0  p-0'
                                                onClick={handleDelete}
                                            >
                                                <i className="bi bi-trash3 text-danger"></i>
                                            </Button>
                                            : null
                                        }
                                    </div>
                                </th>
                                <th className='py-3 col-2 зч-2 align-middle'>
                                    Назва
                                </th>
                                <th className='py-3 px-2 col-2 align-middle'>
                                    Бренд
                                </th>
                                <th className='py-3 px-2 col-2 align-middle'>
                                    Категорія
                                </th>
                                <th className='py-3 px-2 col-1 align-middle'>
                                    Артикул
                                </th>
                                <th className='py-3 px-2 col-1 align-middle'>
                                    Штрихкод
                                </th>
                                <th className='py-3 px-2 col-1 align-middle text-start'>
                                    Залишки
                                </th>
                                <th className='py-3 px-2 pe-0 col-1 align-middle'></th>
                            </tr>
                            </thead>
                            <tbody>
                            {storageProducts?.map((product: StorageProductRelationProxy, index) => (
                                <tr key={index + product?.id}  className='border-bottom'>
                                    <td className='align-middle ps-4 py-4 pe-2'>
                                        <Form.Check
                                            type="checkbox"
                                            checked={markedForDeletionArray.includes(product.id)}
                                            onChange={() => handleChildCheckboxChange(product.id)}
                                        />
                                    </td>
                                    <td className='align-middle  pe-2'>{product?.product?.name}</td>
                                    <td className='align-middle  pe-2'>{product?.product?.brand}</td>
                                    <td className='align-middle pe-2'>
                                        <RenderProductCategoryName
                                            category={product?.product?.productCategoryNew}
                                            isArchive={product?.product?.deleted}
                                        />
                                    </td >
                                    <td className='align-middle  pe-2'>{product?.product?.sku}</td>
                                    <td className='align-middle  pe-2'>{product?.product?.barcode}</td>
                                    <td className='align-middle  pe-2'>
                                        {product?.quantity}
                                        {product?.product?.unit?.name}
                                        {product?.product?.alternativeUnit?.name
                                            ?
                                            `(${product?.product?.alternativeUnit?.name})`
                                            : null
                                        }
                                    </td>
                                    <td className='align-middle'>
                                        <div className="d-flex justify-content-end">
                                            <OverlayTrigger overlay={<Tooltip>{t('Add Product')}</Tooltip>}>
                                                <Button
                                                    variant='outline-secondary'
                                                    className="outline-secondary text-dark m-1 p-0"
                                                    onClick={() => handleAddModal(product)}
                                                    style={{ height: '39px', width: '39px' }}
                                                >
                                                    <i className="bi bi-plus"></i>
                                                </Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip>{t('Write-off product')}</Tooltip>}>
                                                <Button
                                                    variant='outline-secondary'
                                                    className="outline-secondary text-dark m-1 p-0"
                                                    onClick={() => handleWriteOfModal(product)}
                                                    style={{ height: '39px', width: '39px' }}
                                                >
                                                    <i className="bi bi-dash"></i>
                                                </Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip>{t('Move product')}</Tooltip>}>
                                                <Button
                                                    variant='outline-secondary'
                                                    className="outline-secondary text-dark m-1 p-0"
                                                    onClick={() => handleMoveModal(product)}
                                                    style={{ height: '39px', width: '39px' }}
                                                >
                                                    <i className="bi bi-reply"></i>
                                                </Button>
                                            </OverlayTrigger>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    ) : null
                }
            </div>
            {
                products?.length === 0 ?
                    <Container className="mt-5">
                        <Row className="mt-5">
                            <Col xs={12}>
                                <h4 className="head-personal-data text-center fw-normal pt-5 mt-5">
                                    Товари на складі відсутні. Будь ласка, додайте товар.

                                </h4>

                                <div className='text-center mt-3'>
                                    <Button className='px-4' to={"/"}>
                                        <i className='bi bi-plus-lg fw-bold me-1 w-100 h-100'></i> {t('Add Product')}
                                    </Button>
                                </div>

                            </Col>
                        </Row>
                    </Container> : null
            }
            {!!totalItems ? (
                <div className='d-flex justify-content-between align-items-center mt-4 mb-5'>
                    <Pagination
                        t={t}
                        pages={Number(pageCount)}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        firstItemIndex={firstItemIndex}
                        lastItemIndex={lastItemIndex}
                        totalItems={totalItems}
                    />
                </div>
            ) : null}

            {isErrorModalOpen ?
                <ErrorModalComponent
                    i18n={i18next}
                    onClose={() => {
                        setIsErrorModalOpen(null)
                    }}
                    isOpen={!!isErrorModalOpen}
                    currentError={isErrorModalOpen}
                /> : null
            }
            <AddGoodsWarehouseModal  show={showAddModal} handleModal={handleAddModal} chosenProduct={chosenProduct} storageId={storageId}/>
            <WriteOffModal  show={showWriteOfModal}
                            handleModal={handleWriteOfModal}
                            chosenProduct={chosenProduct}
                            writeOffReasons={writeOffReasons}

            />
            <DeleteGoodsModals show={showDeleteModal} />
            <MoveGoodsModal show={showMoveModal} refetch={refetch} handleModal={handleMoveModal} chosenProduct={chosenProduct} warehouses={transformUnitsForSelect()}
                            storageId={+storageId}
            />

        </>
    );
};

export default WarehousesGoodsTable;