import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import React from 'react';

type EmptyListPropsType = {
  children?: React.ReactNode;
  title: string;
};

const EmptyList: React.FC<EmptyListPropsType> = ({ children, title }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row className='w-100 justify-content-md-center'>
        <Col
          className='mt-5 pt-lg-5'>
          <h5 className='text-center font-weight-bold m-0 mt-lg-5'>{t(title)}</h5>
        </Col>
      </Row>
      <Row className='w-100 justify-content-md-center mt-4'>
        <Col md={3} className='d-flex justify-content-center'>
          {children}
        </Col>
      </Row>
    </>
  );
};

export default EmptyList;
