import {
  TechMapCategoryNewProxy,
  TechnologicalMapServiceRelationProxy
} from '@me-team/host/graphql/types';
import {TFunction} from 'i18next';
import * as Yup from 'yup';

export const calculateTechMapConnectServiceCount = (connectServices: TechnologicalMapServiceRelationProxy[]) => {
  if (!connectServices || connectServices?.length === 0) {
    return 0;
  }
  return connectServices?.length
}

export const techMapFormValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string().trim().required(t('validation.required')),
    categoryId: Yup.number().required(t('validation.required')),
  });

export const getStartValueCategory = (productCategoryNew?: TechMapCategoryNewProxy ): number => {
  return productCategoryNew?.parentCategory?.id
    ? productCategoryNew.parentCategory.id
    : productCategoryNew?.id;
};

export const getStartValueSubCategory = (productCategoryNew?: TechMapCategoryNewProxy): number | null => {
  return productCategoryNew?.parentCategory?.id
    ? productCategoryNew.id || null
    : null;
};

export const getCategoryName = (productCategoryNew?: TechMapCategoryNewProxy): string => {
  return productCategoryNew?.parentCategory?.name
    ? productCategoryNew.parentCategory.name
    : productCategoryNew?.name;
};

export const getSubCategoryName = (productCategoryNew?: TechMapCategoryNewProxy): string | null => {
  return productCategoryNew?.parentCategory?.name
    ? productCategoryNew.name || null
    : null;
};