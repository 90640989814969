import { Modal, Button, Row, Col } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import { useTranslation } from "react-i18next";
import { ReactSelect } from "@services/ui-components";
import {StorageProductRelationProxy, StorageProxy, UnitProxy} from "@me-team/host/graphql/types";
import {validationSchemaAddProduct} from "../../Warehouse/modals/modalsUtils";
import {useCreateStorageMutation} from "@me-team/host/src/modules/warehouses/graphql/warehouses.hooks";
import {
    useCreateStorageProductRelationMutation
} from "@me-team/host/src/modules/warehouses_goods/graphql/warehouses_goods.hooks";
import React from "react";

export interface AddGoodsWarehouseModalProps {
    show: boolean;
    handleModal: () => void;
    refetch: () => void;
    toggleShowToast?: (text: string) => void;
    chosenProduct?: StorageProductRelationProxy;
    warehouse?: any;
    storageId?: number
}
const AddGoodsWarehouseModal: React.FC<AddGoodsWarehouseModalProps> = ({ show, handleModal, refetch, storages, chosenProduct, storageId }) => {
    const { t } = useTranslation();
    const productId = chosenProduct?.id
    const initialValues = {
        quantity: null,
        unit : null
    };
    const transformUnitsForSelect = (units: UnitProxy[]) => {
        return units?.map(unit  => ({
            value: unit?.id,
            label: unit?.name
        }));
    };
    const [createStorageProductRelation] = useCreateStorageProductRelationMutation({
        context: {
            errorType: 'local',
        },
    });
    const handleSubmit = async (values: typeof initialValues, { setFieldError }: FormikHelpers<typeof initialValues>) => {

        try {
            const response = await createStorageProductRelation({
                variables: {
                    storageId: +storageId,
                    productId: productId,
                    input: values
                }
            });
        } catch (error) {
            console.error(error)
        }
        refetch();
        handleModal()
    };

    return (
        <Modal size="lg" show={show} onHide={handleModal} centered>
            <Modal.Header className="border-0" closeButton>
                <h5 className="m-0"> Додати Товар</h5>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchemaAddProduct(t)}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                >
                    {({ values, errors, setFieldValue }) => (
                        <Form>
                            <Row>
                                <Col lg={2} xs={4} className="d-flex pr-0">
                                    <label htmlFor="addWeight-field" className="mb-0 text-dark form-label required text-break">
                                        <span className="text-dark fw-bold mt-1 fs-7">Вага для Додавання </span>
                                        <span className="text-danger ms-1 text-break fs-7">*</span>
                                    </label>
                                </Col>
                                <Col lg={10} xs={8}>
                                    <Field
                                        type="number"
                                        name="quantity"
                                        placeholder={"Додайте Вагу"}
                                        className={`fs-7 form-control ${errors.quantity ? 'is-invalid' : ''}`}
                                        maxLength={50}
                                        id="addWeight-field"
                                        // disabled={isDisabled}
                                    />

                                    <ErrorMessage name="quantity" component="div" className="text-danger" />
                                </Col>
                            </Row>
                            <Row className="pt-3 mb-3">
                                <Col lg={2} xs={4} className="d-flex pr-0">
                                    <label htmlFor="storageType" className="mb-0 text-dark form-label required text-break">
                                        <span className="text-dark fw-bold mt-1 fs-7">Одиниця Виміру </span>
                                        <span className="text-danger ms-1 text-break fs-7">*</span>
                                    </label>
                                </Col>
                                <Col lg={10} xs={8}>
                                    <ReactSelect
                                        options={transformUnitsForSelect(chosenProduct?.product?.unitGroup?.units)}
                                        onChange={(option) => setFieldValue('unit', option.value)}
                                        // value={storages.find((option) => option.value === values.storageType) || null}
                                        // disabled={isDisabled}
                                        placeholder={chosenProduct?.product?.unitGroup?.units[0]?.name}
                                    />

                                    <ErrorMessage name="unit" component="div" className="text-danger" />
                                </Col>
                            </Row>

                            <Row className="d-flex justify-content-end pt-6">
                                <Col xs={6} sm="auto" className="mb-sm-0 pe-2">
                                    <Button
                                        variant="outline-primary"
                                        className="fw-normal custom-btn rounded-1"
                                        onClick={handleModal}
                                    >
                                        {t("Cancel")}
                                    </Button>
                                </Col>
                                <Col xs={6} sm="auto" className="mb-sm-0 ps-2">
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="fw-bold custom-btn rounded-1"
                                    >
                                        {t('Save')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default AddGoodsWarehouseModal;