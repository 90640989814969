import React from 'react';

const Spinner: React.FC = () => {
  return (
    <div className='d-flex align-items-center justify-content-center bg-white vh-100'>
      <div className='preloader__spinner'></div>
    </div>
  );
};

export default Spinner;