import React from "react";
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import { useTranslation } from "react-i18next";
import { ReactSelect } from "@services/ui-components";
import {
    useUpdateStorageMutation
} from "@me-team/host/src/modules/warehouses/graphql/warehouses.hooks";
import {WearhousesModalProps} from "../../Warehouse/modals/modalsUtils";
import {
    useUpdateStorageProductRelationMutation
} from "@me-team/host/src/modules/warehouses_goods/graphql/warehouses_goods.hooks";
import {UnitProxy, UpdateStorageProductRelationInput} from "@me-team/host/graphql/types";

// import {warehouseValidationSchema, WearhousesModalProps} from "./modalsUtils"

const WriteOffModal: React.FC<WearhousesModalProps> = ({ show, handleModal, refetch, storages, warehouse, storageProductRelationId, chosenProduct, writeOffReasons}) => {
    const { t } = useTranslation();
    const isDisabled = warehouse?.default
    const initialValues: UpdateStorageProductRelationInput = {
        quantity: null,
        unit: null,
        writeOffReason: null
    };
    const [updateStorageProductRelation] = useUpdateStorageProductRelationMutation({
        context: {
            errorType: 'local',
        },
    });
    const handleSubmit = async (values: typeof initialValues, { setFieldError }: FormikHelpers<typeof initialValues>) => {

        try {
            const input = {
                storageProductRelationId: +chosenProduct?.id,
                    input: {values, quantity:  values?.quantity}
            }
            const response = await updateStorageProductRelation({
                variables: {
                    storageProductRelationId: +chosenProduct?.id,
                    input: {... values, quantity:  +values?.quantity}
                }
            });
        } catch (error) {

        }
        // refetch();
        // handleModal()
    };
    const transformUnitsForSelect = (units: UnitProxy[]) => {
        return units?.map(unit  => ({
            value: unit?.id,
            label: unit?.name
        }));
    };
    return (
        <Modal size="lg" show={show} onHide={handleModal} centered>
            <Modal.Header className="border-0" closeButton>
                <h5 className="m-0">Списати Товар</h5>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    // validationSchema={warehouseValidationSchema(t)}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                >
                    {({ values, errors, setFieldValue }) => (
                        <Form>
                            <Row>
                                <Col lg={2} xs={4} className="d-flex pr-0">
                                    <label htmlFor="createWarehouseModal-name-field" className="mb-0 text-dark form-label required text-break">
                                        <span className="text-dark fw-bold mt-1 fs-7">Кількість для Списання </span>
                                        <span className="text-danger ms-1 text-break fs-7">*</span>
                                    </label>
                                </Col>
                                <Col lg={10} xs={8}>
                                    <Field
                                        type="text"
                                        name="quantity"
                                        placeholder={"Додайте Кількість"}
                                        className={`fs-7 form-control ${errors.quantity ? 'is-invalid' : ''}`}
                                        maxLength={50}
                                        id="createWarehouseModal-name-field"
                                        disabled={isDisabled}
                                    />
                                    <ErrorMessage name="quantity" component="div" className="text-danger" />
                                </Col>
                            </Row>
                            <Row className="pt-3 ">
                                <Col lg={2} xs={4} className="d-flex pr-0">
                                    <label htmlFor="unit" className="mb-0 text-dark form-label required text-break">
                                        <span className="text-dark fw-bold mt-1 fs-7">Одиниця Виміру  *</span>
                                        <span className="text-danger ms-1 text-break fs-7">*</span>
                                    </label>
                                </Col>
                                <Col lg={10} xs={8}>
                                    <ReactSelect
                                        options={transformUnitsForSelect(chosenProduct?.product?.unitGroup?.units)}
                                        onChange={(option) => setFieldValue('unit', option.value)}
                                        // value={storages.find((option) => option.value === values.storageType) || null}
                                        disabled={isDisabled}
                                        placeholder={"Kilogram"}
                                    />

                                    <ErrorMessage name="unit" component="div" className="text-danger" />
                                </Col>
                            </Row>
                            <Row className="pt-3 mb-3">
                                <Col lg={2} xs={4} className="d-flex pr-0">
                                    <label htmlFor="writeOffReason" className="mb-0 text-dark form-label required text-break">
                                        <span className="text-dark fw-bold mt-1 fs-7">Причина Списання </span>
                                        <span className="text-danger ms-1 text-break fs-7">*</span>
                                    </label>
                                </Col>
                                <Col lg={10} xs={8}>
                                    <ReactSelect
                                        options={writeOffReasons}
                                        onChange={(option) => setFieldValue('writeOffReason', option.value)}
                                        // value={storages.find((option) => option.value === values.storageType) || null}
                                        disabled={isDisabled}
                                        placeholder={"Обрати Причину"}
                                    />

                                    <ErrorMessage name="writeOffReasons" component="div" className="text-danger" />
                                </Col>
                            </Row>

                            <Row className="d-flex justify-content-end pt-6">
                                <Col xs={6} sm="auto" className="mb-sm-0 pe-2">
                                    <Button
                                        variant="outline-primary"
                                        className="fw-normal custom-btn rounded-1"
                                        onClick={handleModal}
                                    >
                                        {t("Cancel")}
                                    </Button>
                                </Col>
                                <Col xs={6} sm="auto" className="mb-sm-0 ps-2">
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="fw-bold custom-btn rounded-1"
                                    >
                                        {!warehouse?.deleted ? t('Save') : t('Restore')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default WriteOffModal;