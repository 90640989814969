import  React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col,Button, Row } from "react-bootstrap";
import { TableHeader} from '@services/ui-components';
import VioletBadge from '@services/ui-components/src/VioletBadge/VioletBadge';
import WarehousesTable from '../Warehouse/WarehousesTable/WarehousesTable';
import {useReactiveVar} from "@apollo/client";
import {currentBranchIdVar, currentCompanyIdVar, currentUserRoleVar} from "@me-team/host/src/apollo/globalVar/state";
import {ProductsFilterI} from "./types";
import WarehousesGoodsFilter from "./components/WarehousesGoodsFilter";
import WarehousesGoodsTable from "./components/WarehousesGoodsTable";
import {useNavigate, useParams} from "react-router-dom";

const WarehousesGoods: React.FC = () => {
    const { t } = useTranslation()
    const {storageId} = useParams();
    const navigate = useNavigate();
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const [showPositionModal, setShowPositionModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const handleAddWarehouseModal = () => setShowPositionModal(prevState => !prevState)
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const userRole = useReactiveVar(currentUserRoleVar);
    // const isEmployeeRole = +userRole === UserRole.EMPLOYEE; // change
    const [showFilters, setShowFilters] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);

    const initialValueFilter: ProductsFilterI = {
        nameSkuBarcode: null,
        categoryId: null,
        brand: null,
        stockStatus: null,
    }

    const [filters, setFilters] = useState<ProductsFilterI>(initialValueFilter);

    const toggleFilters = () => setShowFilters(!showFilters);


    return (
        <>
            <VioletBadge
                text={t('Lorem')}
            />
            <TableHeader
                title={t('Склад на вул. Хрещатик')}
                paths={[
                    { path: "/warehouses", label: t('Складський облік') },

                    { path: "/warehouses", label: t('Warehouses') },
                    { path: "/warehouses", label: t('Warehouses') },
                ]}
                buttons={
                    <Row
                        className='gx-1 w-100  d-flex flex-lg-nowrap justify-content-lg-end gap-3'>
                        {
                            true    //del
                                ?
                                <Col
                                    xs={12}
                                    md={6}
                                    lg={7}
                                >
                                    <Button
                                        variant='primary'
                                        className='w-100 text-white mb-3 mb-lg-0 '
                                        onClick={()=> navigate('/warehouse/add-product/' + storageId)}
                                    >
                                        <i className='bi bi-plus-lg fw-bold me-1 w-100 h-100'></i>
                                        {t('Add Product')}
                                    </Button>
                                </Col>
                                : null
                        }
                        <Col
                            xs={12}
                            md={6}
                            lg={5}
                            className='px-0'
                        >
                            <Button
                                variant='additional-violet'
                                className=' fw-normal border-primary text-primary w-100 mb-3 mb-lg-0'
                                onClick={toggleFilters}
                            >
                                <i className='bi bi-funnel me-1'></i>
                                {t('filters')}
                            </Button>
                        </Col>
                    </Row>
                }

                showFilters={showFilters}
            >
                <WarehousesGoodsFilter setFilters={setFilters}/>
            </TableHeader >
            <WarehousesGoodsTable filters={filters} />
            {/*<WarehousesTable show={showPositionModal} handleModal={handleAddWarehouseModal} branchId={currentBranchId} currentPage={currentPage} setCurrentPage={setCurrentPage}/>*/}
        </>
    );
};

export default WarehousesGoods;