import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, OverlayTrigger, Tooltip, Table} from "react-bootstrap";
import {EmptyList, Pagination, Spinner} from '@services/ui-components';
import {useReactiveVar} from "@apollo/client";
import {currentBranchIdVar} from "@me-team/host/src/apollo/globalVar/state";
import {
    useGetAddNewProductListQuery
} from "@me-team/host/src/modules/addNewProductList/graphql/addNewProductList.hooks";
import RenderProductCategoryName from "../../WarehousesGoods/components/RenderProductCategoryName";
import {ProductProxy} from "@me-team/host/graphql/types";
import {useParams} from "react-router-dom";
import AddProductListModal from "../AddProductListModal/AddProductListModal";
interface ProductsTableProps {
    filters: {
        brand: string,
        categoryId: number,
        nameSkuBarcode: string,
    },
    handleModal: (i?: ProductProxy) => void
    setName: any
    showModal: boolean, onHide: () => void, refetch?: () => void, existingProduct: ProductProxy, setExistingProduct: (i?: any) => void, storageId?: number
}
const AddNewProductListTable: React.FC<ProductsTableProps> = ({filters, handleModal, setName, showModal, existingProduct, setExistingProduct}) => {
    const { t } = useTranslation()
    const {id} = useParams();
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const [currentPage, setCurrentPage] = useState(1);
    const { data , loading, refetch} = useGetAddNewProductListQuery({
        variables: { page: currentPage, itemsPerPage: 20, branchesPaginatedId: +currentBranchId, nameSkuBarcode: filters?.nameSkuBarcode, categoryId: filters?.categoryId, brand: filters?.brand, storagesPaginated: +id },
        context: {
            errorType: 'local',
        },
        onCompleted: (data) => {
            setName(data?.user?.company?.branchesPaginated?.branches[0]?.storagesPaginated?.storages[0]?.name || '');
        },
    });

    const products = data?.user?.company?.branchesPaginated?.branches[0]?.storagesPaginated?.storages[0]?.outOfStockProducts?.products
    const outOfStockProducts = data?.user?.company?.branchesPaginated?.branches[0]?.storagesPaginated?.storages[0]?.outOfStockProducts
    const firstItemIndex = (currentPage - 1) * 20 + 1;
    const lastItemIndex = Math.min(currentPage * 20, outOfStockProducts?.totalCount);
    const handlePageChange = (pageNumber: number) => {setCurrentPage(pageNumber)};
    if (loading) return <Spinner/>;
    return (
        <>
            <Table responsive>
                <thead className='fs-7'>
                <tr>
                    <th className='px-4'> {t('ProductsList.Name')}</th>
                    <th>{t('ProductsList.Brand')}</th>
                    <th>{t('ProductsList.Category')}</th>
                    <th>{t('ProductsList.SKU')}</th>
                    <th>{t('ProductsList.Barcode')}</th>
                    <th>{t('ProductsList.Unit')}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {
                    products?.map((i) => (
                        <tr key={i?.id}>
                            <td className={`px-4 'text-primary'} align-middle`}>{i?.name} </td>
                            <td className={` align-middle`}>{i?.brand}</td>
                            <td className={` align-middle`}>
                                <RenderProductCategoryName category={i?.productCategoryNew} isArchive={false} />
                            </td>
                            <td className={` align-middle`}>{i?.sku}</td>
                            <td className={` align-middle`}>{i?.barcode}</td>
                            <td className={` align-middle`}>
                                {i?.unit?.name}
                                {i?.alternativeUnit?.name
                                    ?
                                    `(${i?.alternativeUnit?.name})`
                                    : null
                                }
                            </td>
                            <td>
                                <div className="d-flex justify-content-end">
                                    <OverlayTrigger overlay={<Tooltip>{t('Add Product')}</Tooltip>}>
                                        <Button
                                            variant='outline-secondary'
                                            className="outline-secondary text-dark m-1 p-0"
                                            onClick={() => handleModal(i)}
                                            style={{height: '39px', width: '39px'}}
                                        >
                                            <i className="bi bi-plus"></i>
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
            {!!outOfStockProducts?.totalCount ? (
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <Pagination
                            t={t}
                            pages={Number(outOfStockProducts?.pageCount)}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            firstItemIndex={firstItemIndex}
                            lastItemIndex={lastItemIndex}
                            totalItems={outOfStockProducts?.totalCount}
                        />
                    </div>
                ) :
                <EmptyList title={"За заданими значеннями у фільтрах або пошуку товарів не знайдено."} />
            }
            <AddProductListModal
                show={showModal}
                onHide={()=> ''}
                handleModal={handleModal}
                existingProduct={existingProduct}
                setExistingProduct={()=> setExistingProduct(null) }
                storageId={+id}
                refetch={refetch}
            />
        </>
);
};

export default AddNewProductListTable;