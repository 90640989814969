/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetTechMapsCategoriesDocument = gql`
    query GetTechMapsCategories($page: Int, $itemsPerPage: Int, $delete: Boolean) {
  TechMapCategories(page: $page, itemsPerPage: $itemsPerPage, delete: $delete) {
    items {
      id
      name
      comment
      deleted
      parentCategory {
        id
        name
        deleted
      }
      company {
        id
        name
        address
        phone
        website
        deleted
      }
      childCategories {
        id
        name
        deleted
        parentCategory {
          id
          name
          deleted
        }
      }
    }
  }
}
    `;

/**
 * __useGetTechMapsCategoriesQuery__
 *
 * To run a query within a React component, call `useGetTechMapsCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTechMapsCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTechMapsCategoriesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      delete: // value for 'delete'
 *   },
 * });
 */
export function useGetTechMapsCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetTechMapsCategoriesQuery, GetTechMapsCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTechMapsCategoriesQuery, GetTechMapsCategoriesQueryVariables>(GetTechMapsCategoriesDocument, options);
      }
export function useGetTechMapsCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTechMapsCategoriesQuery, GetTechMapsCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTechMapsCategoriesQuery, GetTechMapsCategoriesQueryVariables>(GetTechMapsCategoriesDocument, options);
        }
export function useGetTechMapsCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTechMapsCategoriesQuery, GetTechMapsCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTechMapsCategoriesQuery, GetTechMapsCategoriesQueryVariables>(GetTechMapsCategoriesDocument, options);
        }
export type GetTechMapsCategoriesQueryHookResult = ReturnType<typeof useGetTechMapsCategoriesQuery>;
export type GetTechMapsCategoriesLazyQueryHookResult = ReturnType<typeof useGetTechMapsCategoriesLazyQuery>;
export type GetTechMapsCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetTechMapsCategoriesSuspenseQuery>;
export type GetTechMapsCategoriesQueryResult = Apollo.QueryResult<GetTechMapsCategoriesQuery, GetTechMapsCategoriesQueryVariables>;
export const GetTechnologicalMapsDocument = gql`
    query GetTechnologicalMaps($page: Int, $itemsPerPage: Int, $search: String, $categoryId: Int, $isDeleted: Boolean, $techMapId: Int, $pageTechMapProducts: Int, $itemsPerPageTechMapProducts: Int) {
  user {
    id
    company {
      id
      technologicalMaps(
        page: $page
        itemsPerPage: $itemsPerPage
        search: $search
        categoryId: $categoryId
        isDeleted: $isDeleted
        techMapId: $techMapId
      ) {
        currentPage
        itemsPerPage
        pageCount
        totalCount
        technologicalMaps {
          id
          name
          description
          deleted
          technologicalMapNewCategory {
            id
            name
            deleted
            parentCategory {
              id
              name
              deleted
            }
          }
          technologicalMapProductRelations {
            id
            unit {
              id
              name
            }
            product {
              id
              name
              deleted
            }
            requiredQuantity
          }
          techMapProducts(
            page: $pageTechMapProducts
            itemsPerPage: $itemsPerPageTechMapProducts
          ) {
            totalCount
            techMapProducts {
              id
              requiredQuantity
            }
          }
          technologicalMapServiceRelations {
            id
            connect
            service {
              id
              name
              description
              deleted
              onlineRegistration
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetTechnologicalMapsQuery__
 *
 * To run a query within a React component, call `useGetTechnologicalMapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTechnologicalMapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTechnologicalMapsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      search: // value for 'search'
 *      categoryId: // value for 'categoryId'
 *      isDeleted: // value for 'isDeleted'
 *      techMapId: // value for 'techMapId'
 *      pageTechMapProducts: // value for 'pageTechMapProducts'
 *      itemsPerPageTechMapProducts: // value for 'itemsPerPageTechMapProducts'
 *   },
 * });
 */
export function useGetTechnologicalMapsQuery(baseOptions?: Apollo.QueryHookOptions<GetTechnologicalMapsQuery, GetTechnologicalMapsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTechnologicalMapsQuery, GetTechnologicalMapsQueryVariables>(GetTechnologicalMapsDocument, options);
      }
export function useGetTechnologicalMapsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTechnologicalMapsQuery, GetTechnologicalMapsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTechnologicalMapsQuery, GetTechnologicalMapsQueryVariables>(GetTechnologicalMapsDocument, options);
        }
export function useGetTechnologicalMapsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTechnologicalMapsQuery, GetTechnologicalMapsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTechnologicalMapsQuery, GetTechnologicalMapsQueryVariables>(GetTechnologicalMapsDocument, options);
        }
export type GetTechnologicalMapsQueryHookResult = ReturnType<typeof useGetTechnologicalMapsQuery>;
export type GetTechnologicalMapsLazyQueryHookResult = ReturnType<typeof useGetTechnologicalMapsLazyQuery>;
export type GetTechnologicalMapsSuspenseQueryHookResult = ReturnType<typeof useGetTechnologicalMapsSuspenseQuery>;
export type GetTechnologicalMapsQueryResult = Apollo.QueryResult<GetTechnologicalMapsQuery, GetTechnologicalMapsQueryVariables>;
export const CreateTechMapDocument = gql`
    mutation CreateTechMap($companyId: Int!, $input: TechnologicalMapInput!) {
  createTechnologicalMap(companyId: $companyId, input: $input) {
    id
    name
    description
    technologicalMapProductRelations {
      id
      unit {
        id
        name
      }
      requiredQuantity
    }
    technologicalMapServiceRelations {
      id
      connect
      service {
        id
        deleted
        name
        onlineRegistration
      }
    }
  }
}
    `;
export type CreateTechMapMutationFn = Apollo.MutationFunction<CreateTechMapMutation, CreateTechMapMutationVariables>;

/**
 * __useCreateTechMapMutation__
 *
 * To run a mutation, you first call `useCreateTechMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTechMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTechMapMutation, { data, loading, error }] = useCreateTechMapMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTechMapMutation(baseOptions?: Apollo.MutationHookOptions<CreateTechMapMutation, CreateTechMapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTechMapMutation, CreateTechMapMutationVariables>(CreateTechMapDocument, options);
      }
export type CreateTechMapMutationHookResult = ReturnType<typeof useCreateTechMapMutation>;
export type CreateTechMapMutationResult = Apollo.MutationResult<CreateTechMapMutation>;
export type CreateTechMapMutationOptions = Apollo.BaseMutationOptions<CreateTechMapMutation, CreateTechMapMutationVariables>;
export const CreateTechMapsCategoryDocument = gql`
    mutation CreateTechMapsCategory($companyId: Int!, $input: TechMapCategoryNewInput!) {
  createTechMapCategory(companyId: $companyId, input: $input) {
    id
    name
    comment
    parentCategory {
      id
      name
      deleted
    }
    deleted
  }
}
    `;
export type CreateTechMapsCategoryMutationFn = Apollo.MutationFunction<CreateTechMapsCategoryMutation, CreateTechMapsCategoryMutationVariables>;

/**
 * __useCreateTechMapsCategoryMutation__
 *
 * To run a mutation, you first call `useCreateTechMapsCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTechMapsCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTechMapsCategoryMutation, { data, loading, error }] = useCreateTechMapsCategoryMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTechMapsCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateTechMapsCategoryMutation, CreateTechMapsCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTechMapsCategoryMutation, CreateTechMapsCategoryMutationVariables>(CreateTechMapsCategoryDocument, options);
      }
export type CreateTechMapsCategoryMutationHookResult = ReturnType<typeof useCreateTechMapsCategoryMutation>;
export type CreateTechMapsCategoryMutationResult = Apollo.MutationResult<CreateTechMapsCategoryMutation>;
export type CreateTechMapsCategoryMutationOptions = Apollo.BaseMutationOptions<CreateTechMapsCategoryMutation, CreateTechMapsCategoryMutationVariables>;
export const UpdateTechnologicalMapDocument = gql`
    mutation UpdateTechnologicalMap($technologicalMapId: Int!, $input: TechnologicalMapInput!) {
  updateTechnologicalMap(technologicalMapId: $technologicalMapId, input: $input) {
    id
    name
    description
  }
}
    `;
export type UpdateTechnologicalMapMutationFn = Apollo.MutationFunction<UpdateTechnologicalMapMutation, UpdateTechnologicalMapMutationVariables>;

/**
 * __useUpdateTechnologicalMapMutation__
 *
 * To run a mutation, you first call `useUpdateTechnologicalMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTechnologicalMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTechnologicalMapMutation, { data, loading, error }] = useUpdateTechnologicalMapMutation({
 *   variables: {
 *      technologicalMapId: // value for 'technologicalMapId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTechnologicalMapMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTechnologicalMapMutation, UpdateTechnologicalMapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTechnologicalMapMutation, UpdateTechnologicalMapMutationVariables>(UpdateTechnologicalMapDocument, options);
      }
export type UpdateTechnologicalMapMutationHookResult = ReturnType<typeof useUpdateTechnologicalMapMutation>;
export type UpdateTechnologicalMapMutationResult = Apollo.MutationResult<UpdateTechnologicalMapMutation>;
export type UpdateTechnologicalMapMutationOptions = Apollo.BaseMutationOptions<UpdateTechnologicalMapMutation, UpdateTechnologicalMapMutationVariables>;
export const DeleteTechMapsDocument = gql`
    mutation deleteTechMaps($techMapIds: [Int!]!) {
  deleteTechMaps(techMapIds: $techMapIds) {
    deleted
    failed
  }
}
    `;
export type DeleteTechMapsMutationFn = Apollo.MutationFunction<DeleteTechMapsMutation, DeleteTechMapsMutationVariables>;

/**
 * __useDeleteTechMapsMutation__
 *
 * To run a mutation, you first call `useDeleteTechMapsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTechMapsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTechMapsMutation, { data, loading, error }] = useDeleteTechMapsMutation({
 *   variables: {
 *      techMapIds: // value for 'techMapIds'
 *   },
 * });
 */
export function useDeleteTechMapsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTechMapsMutation, DeleteTechMapsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTechMapsMutation, DeleteTechMapsMutationVariables>(DeleteTechMapsDocument, options);
      }
export type DeleteTechMapsMutationHookResult = ReturnType<typeof useDeleteTechMapsMutation>;
export type DeleteTechMapsMutationResult = Apollo.MutationResult<DeleteTechMapsMutation>;
export type DeleteTechMapsMutationOptions = Apollo.BaseMutationOptions<DeleteTechMapsMutation, DeleteTechMapsMutationVariables>;
export type GetTechMapsCategoriesQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  delete?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetTechMapsCategoriesQuery = { __typename?: 'Query', TechMapCategories?: { __typename?: 'TechMapCategoryNewPaginated', items?: Array<{ __typename?: 'TechMapCategoryNewProxy', id: number, name: string, comment?: string | null, deleted: boolean, parentCategory?: { __typename?: 'TechMapCategoryNewProxy', id: number, name: string, deleted: boolean } | null, company?: { __typename?: 'CompanyProxy', id: number, name: string, address: string, phone: string, website?: string | null, deleted: boolean } | null, childCategories?: Array<{ __typename?: 'TechMapCategoryNewProxy', id: number, name: string, deleted: boolean, parentCategory?: { __typename?: 'TechMapCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null> | null }> | null } | null };

export type GetTechnologicalMapsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  categoryId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  isDeleted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  techMapId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageTechMapProducts?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  itemsPerPageTechMapProducts?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetTechnologicalMapsQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, technologicalMaps?: { __typename?: 'TechnologicalMapPaginated', currentPage?: number | null, itemsPerPage?: number | null, pageCount?: string | null, totalCount?: number | null, technologicalMaps?: Array<{ __typename?: 'TechnologicalMapProxy', id: number, name: string, description?: string | null, deleted: boolean, technologicalMapNewCategory?: { __typename?: 'TechMapCategoryNewProxy', id: number, name: string, deleted: boolean, parentCategory?: { __typename?: 'TechMapCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null, technologicalMapProductRelations?: Array<{ __typename?: 'TechnologicalMapProductRelationProxy', id: number, requiredQuantity?: number | null, unit?: { __typename?: 'UnitProxy', id: number, name: string } | null, product?: { __typename?: 'ProductProxy', id: number, name: string, deleted: boolean } | null }> | null, techMapProducts?: { __typename?: 'TechMapProductPaginated', totalCount?: number | null, techMapProducts?: Array<{ __typename?: 'TechnologicalMapProductRelationProxy', id: number, requiredQuantity?: number | null }> | null } | null, technologicalMapServiceRelations?: Array<{ __typename?: 'TechnologicalMapServiceRelationProxy', id: number, connect: boolean, service?: { __typename?: 'ServiceProxy', id: number, name: string, description?: string | null, deleted: boolean, onlineRegistration: boolean } | null }> | null }> | null } | null } | null } | null };

export type CreateTechMapMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  input: Types.TechnologicalMapInput;
}>;


export type CreateTechMapMutation = { __typename?: 'Mutation', createTechnologicalMap: { __typename?: 'TechnologicalMapProxy', id: number, name: string, description?: string | null, technologicalMapProductRelations?: Array<{ __typename?: 'TechnologicalMapProductRelationProxy', id: number, requiredQuantity?: number | null, unit?: { __typename?: 'UnitProxy', id: number, name: string } | null }> | null, technologicalMapServiceRelations?: Array<{ __typename?: 'TechnologicalMapServiceRelationProxy', id: number, connect: boolean, service?: { __typename?: 'ServiceProxy', id: number, deleted: boolean, name: string, onlineRegistration: boolean } | null }> | null } };

export type CreateTechMapsCategoryMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  input: Types.TechMapCategoryNewInput;
}>;


export type CreateTechMapsCategoryMutation = { __typename?: 'Mutation', createTechMapCategory: { __typename?: 'TechMapCategoryNewProxy', id: number, name: string, comment?: string | null, deleted: boolean, parentCategory?: { __typename?: 'TechMapCategoryNewProxy', id: number, name: string, deleted: boolean } | null } };

export type UpdateTechnologicalMapMutationVariables = Types.Exact<{
  technologicalMapId: Types.Scalars['Int']['input'];
  input: Types.TechnologicalMapInput;
}>;


export type UpdateTechnologicalMapMutation = { __typename?: 'Mutation', updateTechnologicalMap: { __typename?: 'TechnologicalMapProxy', id: number, name: string, description?: string | null } };

export type DeleteTechMapsMutationVariables = Types.Exact<{
  techMapIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;


export type DeleteTechMapsMutation = { __typename?: 'Mutation', deleteTechMaps: { __typename?: 'DeleteResponse', deleted: Array<number>, failed: Array<number> } };
