import React, { useState } from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Button, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import Spinner from "@services/ui-components/src/Spinner/Spinner";
import {
    useGetStorageTypesQuery,
    useGetWarehousesQuery
} from "@me-team/host/src/modules/warehouses/graphql/warehouses.hooks";
import AddWarehouseModal from "../modals/AddWarehouseModal";
import EditWarehouseModal from "../modals/EditWarehouseModal";
import DeleteWarehouseModals from "../modals/DeleteWarehouseModals";
import {useReactiveVar} from "@apollo/client";
import {currentBranchIdVar, currentCompanyIdVar} from "@me-team/host/src/apollo/globalVar/state";
import {Pagination} from "@services/ui-components";
import {StorageProxy} from "@me-team/host/graphql/types";


const WarehousesTable: React.FC = ({ show, handleModal, branchId, currentPage, setCurrentPage}) => {
    const basePath = process.env.REACT_APP_POS_URL;
    const { t } = useTranslation();
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [showWarehouseEditModal, setShowWarehouseEditModal] = useState(false)
    const [warehouseEdit, setWarehouseEdit] = useState<StorageProxy>(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const { data, loading, error, refetch } = useGetWarehousesQuery({
        variables: { page: currentPage, itemsPerPage: itemsPerPage, currentBranchId: +currentBranchId },
        context: {
        errorType: 'global',
        },
    })
    const handlePageChange = (pageNumber: number) => { setCurrentPage(pageNumber)}
    const { data: storageTypes, loading: loadingStorageTypes} = useGetStorageTypesQuery({
        context: {
            errorType: 'local',
        },
    });
    const pages = data?.user?.company?.branchesPaginated?.branches[0]?.storagesPaginated
    const totalItems = data?.user?.company?.branchesPaginated?.branches[0]?.storagesPaginated?.totalCount || 0;
    const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
    const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

    const warehouses = data?.user?.company?.branchesPaginated?.branches[0]?.storagesPaginated?.storages

    const storages = storageTypes?.relations?.storageTypes.map(storage => ({
        value: storage.id,
        label: storage.name
    }));
    const countProductsQuantity = data => data.reduce((count, item) => item.quantity > 0 ? count + 1 : count, 0)

    const editWarehouse = (warehouse: StorageProxy | null) => {
        setShowWarehouseEditModal(prevState => !prevState)
        setWarehouseEdit(warehouse)
    }
    const handleDeleteModal = (warehouse: StorageProxy | null) => {
        setShowDeleteModal(prevState => !prevState)
        setWarehouseEdit(warehouse)
    }
    if (loading ) return <Spinner />;

    return (
        <>
            <Table responsive>
                <thead className='fs-7'>
                    <tr>
                        <th className='px-4'>{t('Warehouse name')}</th>
                        <th>{t('Description')}</th>
                        <th>{t('Warehouse type')}</th>
                        <th>{t('Products quantity')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {
                    warehouses?.map((i) => (
                        <tr key={i?.id}>
                            <td className={`px-4 ${i?.deleted ? 'text-secondary' : 'text-primary'} align-middle`}>{i?.name} </td>
                            <td className={`${i?.deleted ? 'text-secondary' : ''} align-middle`}>{i?.description}</td>
                            <td className={`${i?.deleted ? 'text-secondary' : ''} align-middle`}>{i?.type?.name}</td>
                            <td className={`${i?.deleted ? 'text-secondary' : ''} align-middle`}>
                                {i?.storageProductRelations.length} ({countProductsQuantity(i?.storageProductRelations)})
                            </td>
                            <td className='align-middle' >
                                <div className="d-flex justify-content-end">
                                    {
                                        !i?.deleted ?
                                            <>
                                                {
                                                    !i?.default ?
                                                    <OverlayTrigger overlay={<Tooltip>{t('Delete warehouse')}</Tooltip>}>
                                                        <Button
                                                            variant='outline-secondary'
                                                            className="outline-secondary text-dark m-1 p-0"
                                                            onClick={() => handleDeleteModal(i)}
                                                            style={{ height: '39px', width: '39px' }}
                                                        >
                                                            <i className="bi bi-trash"></i>
                                                        </Button>
                                                    </OverlayTrigger> : null
                                                }
                                                <OverlayTrigger overlay={<Tooltip>{t('Warehouse product')}</Tooltip>}>
                                                    <Button
                                                        variant='outline-secondary'
                                                        className="outline-secondary text-dark m-1 p-0"
                                                        style={{ height: '39px', width: '39px' }}
                                                    >
                                                        <Link
                                                            to={`warehouses-goods/${currentBranchId}/${i?.id}`}
                                                            // to={`${basePath}/warehouse/storage/branch/${currentBranchId}/${i?.id}`}
                                                            className='text-decoration-none text-dark'
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '100%',
                                                                height: '100%',
                                                                textAlign: 'center',
                                                                color: '#1F3354'
                                                            }}
                                                        >
                                                            <i className="bi bi-basket"></i>
                                                        </Link>
                                                    </Button>
                                                </OverlayTrigger>
                                                <OverlayTrigger overlay={<Tooltip>{t('Edit')}</Tooltip>}>
                                                    <Button
                                                        variant='outline-secondary'
                                                        className="outline-secondary text-dark m-1 p-0"
                                                        onClick={() => editWarehouse(i)}
                                                        style={{ height: '39px', width: '39px' }}
                                                    >
                                                        <i className="bi bi-pencil"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                            </>
                                            :
                                            <>
                                                <span
                                                    className='badge bg-additional-grey text-grey px-3 py-2 m-1 rounded  mb-xl-0 d-flex justify-content-center align-items-center'

                                                >
                                                    {t('Archive')}
                                                </span>

                                                <OverlayTrigger overlay={<Tooltip>{t('Renew')} </Tooltip>}>
                                                    <Button
                                                        variant='outline-secondary'
                                                        className="outline-secondary text-dark m-1 p-0"
                                                        onClick={() => editWarehouse(i)}
                                                        style={{ height: '39px', width: '39px' }}
                                                    >
                                                        <i className="bi bi-arrow-counterclockwise"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                            </>
                                    }
                                </div>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>

            <div className="d-flex justify-content-between align-items-center mb-3" >
                <Pagination
                    t={t}
                    pages={Number(pages?.pageCount)}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    firstItemIndex={firstItemIndex}
                    lastItemIndex={lastItemIndex}
                    totalItems={pages?.totalCount}
                />
            </div >
            <AddWarehouseModal show={show} handleModal={handleModal} branchId={branchId} storages={storages}  refetch={refetch}/>
            <EditWarehouseModal show={showWarehouseEditModal} handleModal={() => editWarehouse(null)} warehouse={warehouseEdit} storages={storages}  refetch={refetch} />
            <DeleteWarehouseModals show={showDeleteModal} handleModal={()=> setShowDeleteModal(false)} warehouse={warehouseEdit} refetch={refetch} />
        </>
    );
};

export default WarehousesTable;
