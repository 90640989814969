/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetBrandsDocument = gql`
    query GetBrands($companyId: Int!) {
  companyBrands(companyId: $companyId) {
    name
  }
}
    `;

/**
 * __useGetBrandsQuery__
 *
 * To run a query within a React component, call `useGetBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetBrandsQuery(baseOptions: Apollo.QueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables> & ({ variables: GetBrandsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
      }
export function useGetBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
        }
export function useGetBrandsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
        }
export type GetBrandsQueryHookResult = ReturnType<typeof useGetBrandsQuery>;
export type GetBrandsLazyQueryHookResult = ReturnType<typeof useGetBrandsLazyQuery>;
export type GetBrandsSuspenseQueryHookResult = ReturnType<typeof useGetBrandsSuspenseQuery>;
export type GetBrandsQueryResult = Apollo.QueryResult<GetBrandsQuery, GetBrandsQueryVariables>;
export const GetProductCategoriesWarehouseDocument = gql`
    query GetProductCategoriesWarehouse {
  user {
    id
    company {
      id
      productCategories {
        id
        name
        deleted
        parentCategory {
          id
          name
          deleted
        }
        childCategories {
          id
          name
          deleted
        }
      }
    }
  }
}
    `;

/**
 * __useGetProductCategoriesWarehouseQuery__
 *
 * To run a query within a React component, call `useGetProductCategoriesWarehouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductCategoriesWarehouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductCategoriesWarehouseQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductCategoriesWarehouseQuery(baseOptions?: Apollo.QueryHookOptions<GetProductCategoriesWarehouseQuery, GetProductCategoriesWarehouseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductCategoriesWarehouseQuery, GetProductCategoriesWarehouseQueryVariables>(GetProductCategoriesWarehouseDocument, options);
      }
export function useGetProductCategoriesWarehouseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductCategoriesWarehouseQuery, GetProductCategoriesWarehouseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductCategoriesWarehouseQuery, GetProductCategoriesWarehouseQueryVariables>(GetProductCategoriesWarehouseDocument, options);
        }
export function useGetProductCategoriesWarehouseSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetProductCategoriesWarehouseQuery, GetProductCategoriesWarehouseQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProductCategoriesWarehouseQuery, GetProductCategoriesWarehouseQueryVariables>(GetProductCategoriesWarehouseDocument, options);
        }
export type GetProductCategoriesWarehouseQueryHookResult = ReturnType<typeof useGetProductCategoriesWarehouseQuery>;
export type GetProductCategoriesWarehouseLazyQueryHookResult = ReturnType<typeof useGetProductCategoriesWarehouseLazyQuery>;
export type GetProductCategoriesWarehouseSuspenseQueryHookResult = ReturnType<typeof useGetProductCategoriesWarehouseSuspenseQuery>;
export type GetProductCategoriesWarehouseQueryResult = Apollo.QueryResult<GetProductCategoriesWarehouseQuery, GetProductCategoriesWarehouseQueryVariables>;
export const GetProductsWarehouseDocument = gql`
    query GetProductsWarehouse($page: Int, $itemsPerPage: Int, $nameSkuBarcode: String, $categoryId: Int, $brand: String, $stockStatus: String) {
  products(
    page: $page
    itemsPerPage: $itemsPerPage
    nameSkuBarcode: $nameSkuBarcode
    categoryId: $categoryId
    brand: $brand
    stockStatus: $stockStatus
  ) {
    items {
      id
      name
      brand
      sku
      barcode
      unit {
        id
        name
      }
      alternativeUnit {
        id
        name
      }
      productStockStatus
      deleted
      productCategoryNew {
        id
        name
        deleted
        parentCategory {
          id
          name
          deleted
        }
      }
    }
    totalCount
    currentPage
    itemsPerPage
    pageCount
  }
}
    `;

/**
 * __useGetProductsWarehouseQuery__
 *
 * To run a query within a React component, call `useGetProductsWarehouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsWarehouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsWarehouseQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      nameSkuBarcode: // value for 'nameSkuBarcode'
 *      categoryId: // value for 'categoryId'
 *      brand: // value for 'brand'
 *      stockStatus: // value for 'stockStatus'
 *   },
 * });
 */
export function useGetProductsWarehouseQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsWarehouseQuery, GetProductsWarehouseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsWarehouseQuery, GetProductsWarehouseQueryVariables>(GetProductsWarehouseDocument, options);
      }
export function useGetProductsWarehouseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsWarehouseQuery, GetProductsWarehouseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsWarehouseQuery, GetProductsWarehouseQueryVariables>(GetProductsWarehouseDocument, options);
        }
export function useGetProductsWarehouseSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetProductsWarehouseQuery, GetProductsWarehouseQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProductsWarehouseQuery, GetProductsWarehouseQueryVariables>(GetProductsWarehouseDocument, options);
        }
export type GetProductsWarehouseQueryHookResult = ReturnType<typeof useGetProductsWarehouseQuery>;
export type GetProductsWarehouseLazyQueryHookResult = ReturnType<typeof useGetProductsWarehouseLazyQuery>;
export type GetProductsWarehouseSuspenseQueryHookResult = ReturnType<typeof useGetProductsWarehouseSuspenseQuery>;
export type GetProductsWarehouseQueryResult = Apollo.QueryResult<GetProductsWarehouseQuery, GetProductsWarehouseQueryVariables>;
export const GetWarehousesGoodsDocument = gql`
    query GetWarehousesGoods($page: Int, $itemsPerPage: Int, $nameSkuBarcode: String, $categoryId: Int, $brand: String, $stockStatus: String, $branchesPaginatedId: Int, $storagesPaginatedId: Int) {
  user {
    company {
      branchesPaginated(id: $branchesPaginatedId) {
        branches {
          storagesPaginated(
            page: $page
            itemsPerPage: $itemsPerPage
            id: $storagesPaginatedId
          ) {
            storages {
              id
              name
              description
              storageProducts(
                itemsPerPage: $itemsPerPage
                nameSkuBarcode: $nameSkuBarcode
                categoryId: $categoryId
                brand: $brand
                stockStatus: $stockStatus
              ) {
                storageProductRelations {
                  id
                  product {
                    id
                    name
                    brand
                    sku
                    barcode
                    productStockStatus
                    deleted
                    unit {
                      id
                      name
                      unitOrder
                    }
                    unitGroup {
                      id
                      name
                      units {
                        id
                        name
                      }
                    }
                    alternativeUnit {
                      id
                      name
                      unitGroup {
                        id
                        name
                        units {
                          id
                          name
                        }
                      }
                    }
                    alternativeValue
                    productCategoryNew {
                      id
                      name
                      deleted
                      parentCategory {
                        id
                        name
                        deleted
                      }
                    }
                  }
                  quantity
                }
              }
            }
            totalCount
            currentPage
            itemsPerPage
            pageCount
          }
        }
      }
    }
  }
  relations {
    writeOffReasons {
      id
      name
    }
  }
}
    `;

/**
 * __useGetWarehousesGoodsQuery__
 *
 * To run a query within a React component, call `useGetWarehousesGoodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehousesGoodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehousesGoodsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      nameSkuBarcode: // value for 'nameSkuBarcode'
 *      categoryId: // value for 'categoryId'
 *      brand: // value for 'brand'
 *      stockStatus: // value for 'stockStatus'
 *      branchesPaginatedId: // value for 'branchesPaginatedId'
 *      storagesPaginatedId: // value for 'storagesPaginatedId'
 *   },
 * });
 */
export function useGetWarehousesGoodsQuery(baseOptions?: Apollo.QueryHookOptions<GetWarehousesGoodsQuery, GetWarehousesGoodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehousesGoodsQuery, GetWarehousesGoodsQueryVariables>(GetWarehousesGoodsDocument, options);
      }
export function useGetWarehousesGoodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehousesGoodsQuery, GetWarehousesGoodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehousesGoodsQuery, GetWarehousesGoodsQueryVariables>(GetWarehousesGoodsDocument, options);
        }
export function useGetWarehousesGoodsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetWarehousesGoodsQuery, GetWarehousesGoodsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWarehousesGoodsQuery, GetWarehousesGoodsQueryVariables>(GetWarehousesGoodsDocument, options);
        }
export type GetWarehousesGoodsQueryHookResult = ReturnType<typeof useGetWarehousesGoodsQuery>;
export type GetWarehousesGoodsLazyQueryHookResult = ReturnType<typeof useGetWarehousesGoodsLazyQuery>;
export type GetWarehousesGoodsSuspenseQueryHookResult = ReturnType<typeof useGetWarehousesGoodsSuspenseQuery>;
export type GetWarehousesGoodsQueryResult = Apollo.QueryResult<GetWarehousesGoodsQuery, GetWarehousesGoodsQueryVariables>;
export const CreateStorageProductRelationDocument = gql`
    mutation CreateStorageProductRelation($storageId: Int!, $productId: Int!, $input: CreateStorageProductRelationInput!) {
  createStorageProductRelation(
    storageId: $storageId
    productId: $productId
    input: $input
  ) {
    id
  }
}
    `;
export type CreateStorageProductRelationMutationFn = Apollo.MutationFunction<CreateStorageProductRelationMutation, CreateStorageProductRelationMutationVariables>;

/**
 * __useCreateStorageProductRelationMutation__
 *
 * To run a mutation, you first call `useCreateStorageProductRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStorageProductRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStorageProductRelationMutation, { data, loading, error }] = useCreateStorageProductRelationMutation({
 *   variables: {
 *      storageId: // value for 'storageId'
 *      productId: // value for 'productId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStorageProductRelationMutation(baseOptions?: Apollo.MutationHookOptions<CreateStorageProductRelationMutation, CreateStorageProductRelationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStorageProductRelationMutation, CreateStorageProductRelationMutationVariables>(CreateStorageProductRelationDocument, options);
      }
export type CreateStorageProductRelationMutationHookResult = ReturnType<typeof useCreateStorageProductRelationMutation>;
export type CreateStorageProductRelationMutationResult = Apollo.MutationResult<CreateStorageProductRelationMutation>;
export type CreateStorageProductRelationMutationOptions = Apollo.BaseMutationOptions<CreateStorageProductRelationMutation, CreateStorageProductRelationMutationVariables>;
export const UpdateStorageProductRelationDocument = gql`
    mutation UpdateStorageProductRelation($storageProductRelationId: Int!, $input: UpdateStorageProductRelationInput!) {
  updateStorageProductRelation(
    storageProductRelationId: $storageProductRelationId
    input: $input
  ) {
    id
  }
}
    `;
export type UpdateStorageProductRelationMutationFn = Apollo.MutationFunction<UpdateStorageProductRelationMutation, UpdateStorageProductRelationMutationVariables>;

/**
 * __useUpdateStorageProductRelationMutation__
 *
 * To run a mutation, you first call `useUpdateStorageProductRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStorageProductRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStorageProductRelationMutation, { data, loading, error }] = useUpdateStorageProductRelationMutation({
 *   variables: {
 *      storageProductRelationId: // value for 'storageProductRelationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStorageProductRelationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStorageProductRelationMutation, UpdateStorageProductRelationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStorageProductRelationMutation, UpdateStorageProductRelationMutationVariables>(UpdateStorageProductRelationDocument, options);
      }
export type UpdateStorageProductRelationMutationHookResult = ReturnType<typeof useUpdateStorageProductRelationMutation>;
export type UpdateStorageProductRelationMutationResult = Apollo.MutationResult<UpdateStorageProductRelationMutation>;
export type UpdateStorageProductRelationMutationOptions = Apollo.BaseMutationOptions<UpdateStorageProductRelationMutation, UpdateStorageProductRelationMutationVariables>;
export const DeleteStorageProductRelationDocument = gql`
    mutation DeleteStorageProductRelation($id: Int!) {
  deleteStorageProductRelation(storageProductRelationId: $id)
}
    `;
export type DeleteStorageProductRelationMutationFn = Apollo.MutationFunction<DeleteStorageProductRelationMutation, DeleteStorageProductRelationMutationVariables>;

/**
 * __useDeleteStorageProductRelationMutation__
 *
 * To run a mutation, you first call `useDeleteStorageProductRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStorageProductRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStorageProductRelationMutation, { data, loading, error }] = useDeleteStorageProductRelationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStorageProductRelationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStorageProductRelationMutation, DeleteStorageProductRelationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStorageProductRelationMutation, DeleteStorageProductRelationMutationVariables>(DeleteStorageProductRelationDocument, options);
      }
export type DeleteStorageProductRelationMutationHookResult = ReturnType<typeof useDeleteStorageProductRelationMutation>;
export type DeleteStorageProductRelationMutationResult = Apollo.MutationResult<DeleteStorageProductRelationMutation>;
export type DeleteStorageProductRelationMutationOptions = Apollo.BaseMutationOptions<DeleteStorageProductRelationMutation, DeleteStorageProductRelationMutationVariables>;
export const MovementProductBetweenStoragesDocument = gql`
    mutation MovementProductBetweenStorages($storageProductRelationId: Int!, $storageId: Int!, $quantity: Int, $unitId: Int) {
  movementProductBetweenStorages(
    storageProductRelationId: $storageProductRelationId
    storageId: $storageId
    quantity: $quantity
    unitId: $unitId
  )
}
    `;
export type MovementProductBetweenStoragesMutationFn = Apollo.MutationFunction<MovementProductBetweenStoragesMutation, MovementProductBetweenStoragesMutationVariables>;

/**
 * __useMovementProductBetweenStoragesMutation__
 *
 * To run a mutation, you first call `useMovementProductBetweenStoragesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovementProductBetweenStoragesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movementProductBetweenStoragesMutation, { data, loading, error }] = useMovementProductBetweenStoragesMutation({
 *   variables: {
 *      storageProductRelationId: // value for 'storageProductRelationId'
 *      storageId: // value for 'storageId'
 *      quantity: // value for 'quantity'
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useMovementProductBetweenStoragesMutation(baseOptions?: Apollo.MutationHookOptions<MovementProductBetweenStoragesMutation, MovementProductBetweenStoragesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MovementProductBetweenStoragesMutation, MovementProductBetweenStoragesMutationVariables>(MovementProductBetweenStoragesDocument, options);
      }
export type MovementProductBetweenStoragesMutationHookResult = ReturnType<typeof useMovementProductBetweenStoragesMutation>;
export type MovementProductBetweenStoragesMutationResult = Apollo.MutationResult<MovementProductBetweenStoragesMutation>;
export type MovementProductBetweenStoragesMutationOptions = Apollo.BaseMutationOptions<MovementProductBetweenStoragesMutation, MovementProductBetweenStoragesMutationVariables>;
export type GetBrandsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetBrandsQuery = { __typename?: 'Query', companyBrands: Array<{ __typename?: 'CompanyBrandsProxy', name: string } | null> };

export type GetProductCategoriesWarehouseQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProductCategoriesWarehouseQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, productCategories?: Array<{ __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean, parentCategory?: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean } | null, childCategories?: Array<{ __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean } | null> | null }> | null } | null } | null };

export type GetProductsWarehouseQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  nameSkuBarcode?: Types.InputMaybe<Types.Scalars['String']['input']>;
  categoryId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  brand?: Types.InputMaybe<Types.Scalars['String']['input']>;
  stockStatus?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetProductsWarehouseQuery = { __typename?: 'Query', products?: { __typename?: 'ProductPaginated', totalCount?: number | null, currentPage?: number | null, itemsPerPage?: number | null, pageCount?: string | null, items?: Array<{ __typename?: 'ProductProxy', id: number, name: string, brand?: string | null, sku?: string | null, barcode?: string | null, productStockStatus?: string | null, deleted: boolean, unit?: { __typename?: 'UnitProxy', id: number, name: string } | null, alternativeUnit?: { __typename?: 'UnitProxy', id: number, name: string } | null, productCategoryNew?: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean, parentCategory?: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null }> | null } | null };

export type GetWarehousesGoodsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  nameSkuBarcode?: Types.InputMaybe<Types.Scalars['String']['input']>;
  categoryId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  brand?: Types.InputMaybe<Types.Scalars['String']['input']>;
  stockStatus?: Types.InputMaybe<Types.Scalars['String']['input']>;
  branchesPaginatedId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  storagesPaginatedId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetWarehousesGoodsQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', branchesPaginated?: { __typename?: 'BranchPaginated', branches?: Array<{ __typename?: 'BranchProxy', storagesPaginated?: { __typename?: 'StoragePaginated', totalCount?: number | null, currentPage?: number | null, itemsPerPage?: number | null, pageCount?: string | null, storages?: Array<{ __typename?: 'StorageProxy', id: number, name: string, description?: string | null, storageProducts?: { __typename?: 'StorageProductPaginated', storageProductRelations?: Array<{ __typename?: 'StorageProductRelationProxy', id: number, quantity?: number | null, product?: { __typename?: 'ProductProxy', id: number, name: string, brand?: string | null, sku?: string | null, barcode?: string | null, productStockStatus?: string | null, deleted: boolean, alternativeValue?: number | null, unit?: { __typename?: 'UnitProxy', id: number, name: string, unitOrder?: number | null } | null, unitGroup?: { __typename?: 'UnitGroupProxy', id: number, name: string, units?: Array<{ __typename?: 'UnitProxy', id: number, name: string }> | null } | null, alternativeUnit?: { __typename?: 'UnitProxy', id: number, name: string, unitGroup?: { __typename?: 'UnitGroupProxy', id: number, name: string, units?: Array<{ __typename?: 'UnitProxy', id: number, name: string }> | null } | null } | null, productCategoryNew?: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean, parentCategory?: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null } | null }> | null } | null }> | null } | null }> | null } | null } | null } | null, relations: { __typename?: 'RelationProxy', writeOffReasons?: Array<{ __typename?: 'WriteOffReasonProxy', id: number, name: string }> | null } };

export type CreateStorageProductRelationMutationVariables = Types.Exact<{
  storageId: Types.Scalars['Int']['input'];
  productId: Types.Scalars['Int']['input'];
  input: Types.CreateStorageProductRelationInput;
}>;


export type CreateStorageProductRelationMutation = { __typename?: 'Mutation', createStorageProductRelation: { __typename?: 'StorageProductRelationProxy', id: number } };

export type UpdateStorageProductRelationMutationVariables = Types.Exact<{
  storageProductRelationId: Types.Scalars['Int']['input'];
  input: Types.UpdateStorageProductRelationInput;
}>;


export type UpdateStorageProductRelationMutation = { __typename?: 'Mutation', updateStorageProductRelation: { __typename?: 'StorageProductRelationProxy', id: number } };

export type DeleteStorageProductRelationMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type DeleteStorageProductRelationMutation = { __typename?: 'Mutation', deleteStorageProductRelation: boolean };

export type MovementProductBetweenStoragesMutationVariables = Types.Exact<{
  storageProductRelationId: Types.Scalars['Int']['input'];
  storageId: Types.Scalars['Int']['input'];
  quantity?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  unitId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type MovementProductBetweenStoragesMutation = { __typename?: 'Mutation', movementProductBetweenStorages: boolean };
